.flex-row-res {
  display: flex;
  flex-direction: row;
  align-items: center;
  select {
    padding: 0 !important;
    max-width: 60px;
  }
}

.server-past-rating {
  padding-top: 30px;
}

.wrapper-flatslider {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.calendar-style {
  cursor: pointer;
  font-size: 20px !important;
  margin-left: 10px;
  position: absolute;
  top: 5px;
  right: 0;
}

.server-overall-rating-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  .title-slider {
    margin: 0;
    font-size: 15px;
    font-weight: bold;
  }
  .slider-text {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
  }
}

.flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#list-waitstaff-container {
  .title-handle {
    span {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
    }
  }
}

.no-review-text {
  color: red;
  font-size: 12px;
  font-style: italic;
  display: inline-block;
  margin: 10px 0;
  border: 1px solid red;
  padding: 3px 15px;
  border-radius: 2px;
}

.overall-no-review {
  background: #999999 !important;
  opacity: 0.75;
}

// WaitStaff Overall Rating
.content-overall-rating {
  margin-bottom: 20px;
  .server-overall-rating {
    padding: 20px 0 20px 15px;
    border-radius: 5px;
    border: 1px solid #999999;
    background: #9999994a;
  }
}

.wrapper-has-bathroom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  label {
    cursor: pointer;
    margin: 0 !important;
    .label-text {
      color: #333333 !important;
      &::before {
        font-size: 20px !important;
      }
      font-size: 14px !important;
    }
  }
}

.average-list-sliders {
  padding: 20px 0 20px 15px;
  border-radius: 5px;
  border: 1px solid #999999;
  background: #9999994a;
}

.customer-review {
  .default-text-reason {
    font-size: 14px;
    margin-top: 10px;
  }
  .customer-comment {
    border: 3px solid #666;
    border-radius: 5px;
    padding: 15px;
    font-size: 14px;
    font-weight: normal;
    color: #333;
  }
}

.wrapper-repl-server-comment {
  .server-name-reply {
    font-size: 14px;
    font-style: italic;
    color: #666;
    font-weight: normal;
    margin: 0;
  }
  .title-comment {
    font-size: 12px;
    font-style: italic;
    color: #999;
    margin-bottom: 5px;
  }
  .reply-content {
    border: 2px solid #666;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
}

.wrapper-exclude-rating {
  display: flex;
  flex-direction: row;
  .exclude-rating {
    margin-left: 20px;
    label {
      position: relative;
      input {
        font-size: 20px;
        &:disabled ~ span {
          color: #999999 !important;
          &::before {
            color: #999999 !important;
          }
        }
      }
      span {
        cursor: pointer;
        font-size: 14px !important;
        color: #333333 !important;
        &::before {
          font-size: 16px !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.sliders-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 20px 0;
  justify-content: space-between;
}

.delivery-overall-review, .delivery-content-rating, .delivery-list-slider {
  border: 1px solid #999;
  background: #9999994a;
  padding: 30px 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}