/*
*   General Accordions Views Styles on
*   menu item and restaurant details
*/
.accordion-heading {
  display: block;
}

.text-quantities {
  font-size: 12px;
  width: 40px;
  text-align: center;
}

.guru-presenation-text-margin {
   padding-left: 16px;
}
/** HIGHEST RATED MENU ITEMS **/
.star-rating-count {
  margin-top: -3px;
  font-size: 12px;
}
/*** RESTAURANT MENU ***/
.menu-item-rating-container {
  display: inline-flex;
}
.rated-star-icon {
  color: $orange;
}

.not-rated-star-icon {
  color: $grey;
}

.menu-filtering-text,
.hr-menu-filtering-text {
  display: none;
}
span.restaurant-menu-item {
  color: $green;
  text-decoration: none;
}

ul.restaurant-menu-addon-instructions {
  margin: 0;

  li {
    color: $orange;
    font-size: 1.1rem;
  }
}

.fancytree-ext-table {
  input {
    // !important is needed because my-restaurants-admin.css sets the position
    // absolute for all checkboxes and radio buttons to move them off the screen
    color: #000;
    position: static !important;
    margin: 0;
    padding: 0;
  }

  input[type="number"] {
    font-size: 1.1rem;
    min-width: 4rem;
  }

  label {
    color: $orange;
    font-size: 1.1rem;
  }

  .fancytree-checkbox-placeholder {
    display: inline-block;
    margin-left: 0.5em;
    width: 1em;
  }
}

.restaurant-menu-btn {
  padding: 10px 12px;
  font-size: px2rem(20px);
  text-transform: uppercase;
  background-color: $green;
  color: $white;
}

.restaurant-menu-btn:hover {
  background-color: lighten($green, 15);
  color: $white;
}

.menu-filter-btn {
  font-size: px2rem(20px);
  border: 1px solid $green;
  text-transform: uppercase;
}

/*.goto-item-btn {
  background-color: $green;
  color: $white;
  margin-right: 20px;
}*/
.goto-item-btn {
  width:80px !important;
}

// FancyTree Tree plugin Custom Styles
#tree, #tree-menu {
  margin-left: 5px;
  outline: none;
  box-shadow:none !important;
  position: relative;
}

.fancytree-title {
  font-size:15px !important;
  margin-top:0;
}
.restaurant-menu-item, .menu-item-price {
  display: flex;
  font-size: px2rem(24px) !important;
  p {
    font-size: px2rem(24px) !important;
    margin-right: 4px;
  }
}
.restaurant-menu-item, .item-discount {
  display: flex;
  font-size: px2rem(24px) !important;
  p {
    font-size: px2rem(22px) !important;
    margin-right: 4px;
  }
}
.fancytree-selected {
  background: $lightest-grey !important;
  border: none !important;
}

// .fancytree-focused {
//   background: $blue !important;
//   border: none !important;
// }

// .fancytree-active {
//   background: $blue !important; //TODO use appropiate color
//   border: none !important;
//   color: $white !important;
// }

// .fancytree-active .fancytree-title  {
//   color: $white;
// }

// .fancytree-active .restaurant-menu-item {
//   color: $white;
// }

// .fancytree-active .not-rated-star-icon {
//   color: lighten($grey, 20);
// }
// .fancytree-active .goto-item-btn {
//   color: $white;
//   background-color: $orange;
// }

tr.fancytree-active{
  td {
    background: none !important;
    color: unset !important;
    span.fancytree-title {
      color: unset !important;
    }
  }
}

.fancytree-treefocus {
  border: none !important;
}

.fancytree-treefocus:active {
  border: none !important;
}
table.fancytree-ext-table {
  border-collapse: none !important;
}

/* Define custom width and alignment of fancy tree table columns */
#tree tr td:nth-of-type(1), #tree-menu tr td:nth-of-type(1) {
  color: $orange;
}
.table-tree tr td:nth-of-type(1) {
  color: mediumseagreen;
}

.orange-filter {
  background-color: darken($orange, 15) !important;
  color: $white !important;
  & .fancytree-title span{
    color: $white !important;
  }
  & span,
    .flavor-match i,
    .menu-item-name,
    .flavor-match p{
    color: $white !important;
  }
}

.green-filter {
  background-color: green !important;
  color: $white !important;
  & .fancytree-title span{
    color: $white !important;
  }
  & span,
    .flavor-match i,
    .menu-item-name,
    .flavor-match p{
    color: $white !important;
  }
}

.grey-filter {
  background-color: lightgrey !important;
  color: $coral !important;
  & .fancytree-title span{
    color: black !important;
  }
}
#dietary-restrictions-labels {
  display: block;
  margin-left: 10px;
}

/*** ITEM REVIEWS ***/

.reviews-media-object {
  width:120px;
}

.reviews-media-body {
  padding-top: 10px;
}


/** ADDITIOANAL RESTAURANT DETAILS **/
/* Custom dropdown */
.venue-awards-container .custom-dropdown {
  margin: 0px 100px 0px 100px;
}

.custom-dropdown {
  position: relative;
  display: block;
  vertical-align: middle;
  margin: 10px;
}

.custom-dropdown select {
  background-color: rgba(245,166,35,1.0);
  color: #fff;
  padding: px2rem(10px);
  border: 0;
  margin-right: 0;
  border-radius: 3px;
  text-indent: 0.01px;
  text-overflow: '';
  width: 60px;
  -webkit-appearance: button;
  /* hide default arrow in chrome OSX */
  margin-top: 4px;
}

.custom-dropdown option {
  font-size: .9em;
  border: 0;
  margin: 0;
  border-radius: 3px;
  width: 70px;
  /* hide default arrow in chrome OSX */
}

.custom-dropdown::before,
.custom-dropdown::after {
  content: "";
  position: absolute;
  pointer-events: none;
}

.custom-dropdown::after {
  /*  Custom dropdown arrow */
  content: "\25BC";
  height: 1em;
  font-size: .7em;
  line-height: 1;
  right: 1.2em;
  top: 50%;
  margin-top: -.5em;
}

.custom-dropdown.payment::after {
  content: "";
  display: none;
}

.custom-dropdown select[disabled] {
  color: rgba(0, 0, 0, .9);
}

.custom-dropdown select[disabled]::after {
  color: rgba(0, 0, 0, .9);
}

.custom-dropdown::before {
  background-color: rgba(0, 0, 0, .15);
}

.custom-dropdown::after {
  color: rgba(0, 0, 0, .4);
  content: "";
}

#no-hours-container {
  display: none;
}
#no-venue-hours-btn {
  background-color: $green;
  color: $white;
}
.display-hours-container {
  margin-top: 4px;
  margin-left: 6px;
  padding-right: 0 !important;
  font-size: px2rem(14px);
}

.edit-hours-container {
  display: none;
}
.hours-week-day, #openText, #toText {
  font-size: px2rem(18px);
}

.after-hours-container {
  display: none;
}
.edit-venue-hours {
  padding: 0;
  font-size: 12px;
  margin-top: 12px;
}

#openText, #toText{
  display: inline-block;
  font-size: 1rem;
  margin:0;
}

// Previous button style - From master branch
//.save-hours-btn {
//  display: none;
//  background-color: $green;
//  color: $white;
//  text-transform: uppercase;
//  font-size: px2rem(16px);
//  position: absolute;
//  left: -15px;
//}


/** Modified Save hours button **/
.save-hours-btn {
  // display: none;
  background-color: $green;
  color: $white;
  text-transform: uppercase;
  font-size: 1.75rem;
  //position: absolute;
  //left: 85%;

}

/**  Delete hours button; CSS; Gets applied to all restaurants **/
.delete-hours-btn {
  // display: none;
  background-color: #d9534f;
  color: $white;
  text-transform: uppercase;
  font-size: 1.75rem;
  margin-left: 12.7rem;
  //position: absolute;
  //left: 112%; //170
}

#sav-del-container{
  margin-left:1rem;
  margin-top: 2rem;
}

.save-corkage-btn {
  height:30%;
  width:auto;
  font-size:11px;
  // display:none;
}
//Seating capacity
#saveCapacity {
  height:30%;
  width:auto;
  font-size:11px;
  display:none;
}
.seating-capacity-container {
  margin-left:10px;
  margin-top: 10px;
  margin-bottom: 70px;
  justify-content: center;

}

//Awards
.venue-awards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cart-stars-container {
  margin-top: 5px;
}

.cart-item-details {
 & span {
  font-size: 18px;
 }
 & p {
  padding-left: 2px;
  padding-right:  2px;
  margin-bottom: 0px;
  font-size: 18px;
 }
}

.group-cart-addons {
  margin-bottom: 5px;
  display: inline-block;
  width:100%;
  .text-addon {
    font-size: 12px;
  }
}

.inline-input-icon {
  position: relative;
  font-size: 16px;
}

.inline-input-icon input { text-indent: 10px;}
.inline-input-icon span  {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 16px;
  line-height: 25px;
}
.inline-input-icon span.percent{
  right: 10px;
  left: unset;
}
.inline-input-icon input.percent {
  text-indent: unset;
  padding-right: 30px;
}

input.percent::-webkit-outer-spin-button,
input.percent::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.percent {
    -moz-appearance:textfield;
}

.delivery-tip-btn {
  padding: 4px 1px;
  font-size: 14px;
}
#edit-item-container .new-item-quantity {
  width: 30px;
  padding: 2px;
  text-align: center;
  margin: 0 4px;
  border-radius: 5px;
  border-color: $light-grey;
};

.item-quantity{
  color: #000;
}

.item-name{
  color: black;
}

#custom-tip-amt, #custom-tip-per-amt {
  max-width: 220px;
}

#cart-items-totals {
  display:flex;
  justify-content:flex-end;
  background-color: rgba(2, 132, 61, 0.1);
  line-height: normal;
  padding-top: 12px;
}

/*** MEDIA QUERIES ***/

//Override  styles on bigger screen sizes
@media only screen and (min-width: 769px) {
  .custom-dropdown {
    font-size: 1em;
  }
  .custom-dropdown select {
    min-width: 80px;
  }
  #toText {
    display: inline-block;
  }
  #openText {
    display: inline-block;
  }
}

@media screen and (max-width:320px) {
  .custom-dropdown select {
    height: 3rem;
    width: 5rem;
    padding: 0;
    margin: 0;
  }

  .custom-dropdown select.day-hours-select {
    font-size: px2rem(20px);
    padding: px2rem(12px);
  }

  .save-hours-btn {
    font-size:1.5rem;
  }

  .delete-hours-btn{
    margin-left: 5rem;
    font-size:1.5rem;
  }

  .hours-week-day{
    display: inline-block;
    font-size: 1.3rem;
  }

  h3.col-xs-12.col-sm-3.col-md-4{
    font-size: 1.375rem;
    text-transform: uppercase;
  }

  .col-xs-12.col-sm-12.col-md-5{
    margin-top: -20px;
  }

}

@media screen and (min-width: 321px) and(max-width:420px){

  .custom-dropdown select {
    height: 3rem;
    width: 6rem;
    padding: 0;
    margin: 0;
    padding-left: 4px;
  }

  .custom-dropdown select.day-hours-select {
    font-size: px2rem(20px);
    padding: px2rem(12px);
  }

  .save-hours-btn {
    font-size:1.5rem;
  }

  .delete-hours-btn {
    font-size:1.5rem;
  }

  .hours-week-day{
    display: inline-block;
    font-size: 1.3rem;
  }

  h3.col-xs-12.col-sm-3.col-md-4{
    font-size: 1.375rem;
    text-transform: uppercase;
  }

  .col-xs-12.col-sm-12.col-md-5{
    margin-top: -20px;
  }
}

//fixed the onclick event on safari
.clickSafari { cursor: pointer; }

//tooltip information icon tooltip
.iconInfo {
  display: inline-block;
}

.iconInfo span{
  font-size: 15px;
  text-decoration: none;
  color: #3cb371;
  top:5px;
}
.tooltip {
  min-width: 300px;
  position: fixed;
  z-index: 9999 !important;
}
.tooltip-inner{
  background-color: #aeb1b7;
  color:black;
  cursor:pointer;
  font-size: 14px;
  margin-top: 0px !important;
}

.col-xs-2.col-sm-5.col-md-5.natext{
  font-size: 11px;
}

.collapse-restraunt-additional-details .collapse-body {
  background-color: #ffffff;
  padding: 15px;
}
