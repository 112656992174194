
html {
  overflow:scroll;
  overflow-x:hidden;
}


h1,h2,h3,h4,h5,h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}


.orange {
  color: $orange;
}
.orange:hover {
  color: $green;
}
.black {
  color: $black;
}
.red {
  color: $red;
}
.yellow {
  color: $yellow;
}
.dark-orange {
  color: darken($orange, 20);
}
.brown {
  color: brown;
}
.centerBlock {
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align:left;
}

.dark-green {
  color: $dark-green;
}
.center-flex-block {
  display: flex;
  justify-content: center;
}
.inline-flex-block {
  display: inline-flex;
  justify-content: center;
}
.flex-menu-content {
  width: 50%;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.inline-flex-block.dressCode{
  display: block;
  justify-content: center;

}

.btn-green {
  background-color: $green;
  color: $white;
  margin-left: 2px;
  margin-right: 2px;
}

.btn-green:hover {
  background-color: lighten($green, 8);
  color: $white;
}

.btn-red {
  background-color: $red;
  color: $white;
  margin-left: 2px;
  margin-right: 2px;
}

.btn-red:hover {
  background-color: lighten($red, 8);
  color: $white;
}

.btn-red:active {
  background-color: lighten($red, 8);
  color: $white;
}

.xs-text {
  font-size: 10px;
}
.sm-text {
  font-size: 14px;
}
.md-text {
  font-size: 18px;
}
.lg-text {
  font-size: 22px;
}

.extra-padding {
  padding: 10px 16px;
}
.no-padding {
  padding: 0
}
.no-padding-right{
  padding-right: 0;
}
.no-padding-left{
  padding-left: 0;
}
.flex-block {
  display: flex;
}
.totally-center-block {
  display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  -webkit-justify-content: center; /* Safari 6.1+ */
  -webkit-align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;

}

.float-right {
  float: right;
}

.full-width-btn {
  width: 100%;
  padding: 12px 0;
}

.section-btn {
  background-color: $green;
  color: $white;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 1px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
}

.search-input {
  @include box-sizing('border-box');
  height: 32px;
  width: 100%;
  border: 1px solid $light-grey;
  padding: 10px 16px;
  outline: 0;
  font-size: 0.95em;
  margin-bottom: px2rem(12px);
}

.search-input:focus, .login-input:focus {

  border: 1px solid $light-grey;
  background: $white;
}

/*.btn-primary {
  color: $white !important;
  background: $green !important;
} */
.btn-primary {
    color: #fff;
    background-color: #02843D;
    border-color: #02843D;
   /* border-radius:50px !important; */
    padding: 8px;
}

//Set default  SwealAlert buttons colors
.sweet-alert button {
  background-color: $green;
}
.sweet-alert button.cancel {
  background-color: $grey;
}
.sweet-alert button.confirm {
  background-color: $green;
}
.color-green .sweet-alert button.cancel {
  background-color: $green;
}
.swal2-icon.swal2-info {
  color: $green !important;
  border-color: $green !important;
}
//Override Raterater plugin stars
//Static stars

.raterater-rating-layer .fa-star {
  color: $orange !important;
}

.rating-green-star .fa-star {
  color: $green !important;
}

.raterater-hover-layer .fa-star{
  color: $green !important;
}

//Override Slider colors
.slider-selection, .tick-slider-selection, .in-selection {
  background: $slider-selection !important;
}

//Add the green color just for the custom range sliders
.custom-range-slider, .tick-slider-selection, .in-selection {
  background: $slider-selection !important;
}
.slider-handle {
  background: #fff !important;
}

/* loading dots */
@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.loading-dots {
  color: $green;
}

.loading-dots span {
  color: $green;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loading-dots span:nth-child(2) {
  animation-delay: .2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: .4s;
}

.loading-dots-md {
  font-size: 0px;
  line-height: 15px;
  width:100%;
  height:100%;
  border-radius: 5px;
  z-index:999;
}

.loading-dots-md span {
  font-size: 50px;
  line-height: 0px;
}

.bar {
  height: 18px;
  background: $green;
}

.default-btn-green {
  background-color: mediumseagreen;
  background-image: none;
  color: #fff;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 1px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 320px) { //iPhone5
  .portion-size-align {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 375px) and (min-width: 320px) { // iPhone6
  .portion-size-align {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 412px) and (min-width: 375px) { // iPhone 6 plus and Nexus 6P
  .portion-size-align {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 414px) and (min-width: 412px) { // iPhone 6 plus and Nexus 6P
  .portion-size-align {
    padding-left: 37px;
  }
}


@media only screen and (max-width: 768px) and (min-width: 414px) { // iPad
  .portion-size-align {
    padding-left: 38px;
  }
}

@media only screen and (max-width: 768px) {
  .flex-menu-content {
    width: 100%;
    padding-right: 0;
  }
}

.price-control3 { border:none; width: 97%; display: inline-block !important; }

.to-left-parentItems{
  padding-left: 17px !important;
}
.fa-caret-down:before {
  font-size: x-large ;
}
.fa-caret-right:before {
  font-size: x-large ;
}

.form-actions {
  margin: 0;
  background-color: transparent;
  text-align: center;
}

.ios-logo-header {
  margin-top: 20px;
  margin-left: 20px;
  max-width: 200px;
}

.m-bottom {
  margin-bottom: 7px !important;
}

.d-flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.sensory-naright-text {
  position: relative;
  margin-top: 5px;
  .preference-items {
    margin-left: 20px;
  }
}

//@media screen and (max-device-width:320px) {
//  .custom-dropdown select {
//    width: 45px;
//    font-size: px2rem(16px);
//    padding: 0;
//    margin: 0;
//  }
//}

/* Loading bars */

// .loading-bars {
//   position: relative;
//   margin: 155px auto;
//   width: 200px;
//   z-index: 2;
// }

// .loading-bars svg {
//   position: absolute;

//   ellipse {
//     transform-origin: center;
//   }

//   &:nth-of-type(1) ellipse {
//     @include jelly(1, 0);
//   }

//   &:nth-of-type(2) ellipse {
//     @include jelly(2, 1);
//   }

//   &:nth-of-type(3) ellipse {
//     @include jelly(3, 2)
//   }

//   &:nth-of-type(4) ellipse {
//     @include jelly(4, 3)
//   }

//   &:nth-of-type(5) ellipse {
//     @include jelly(5, 4)
//   }

//   &:nth-of-type(6) ellipse {
//     @include floor(0)
//   }

//   &:nth-of-type(7) ellipse {
//    @include floor(1)
//   }

//   &:nth-of-type(8) ellipse {
//     @include floor(2)
//   }

//   &:nth-of-type(9) ellipse {
//     @include floor(3)
//   }

//   &:nth-of-type(10) ellipse {
//     @include floor(4)
//   }
// }

// @keyframes jump {
//   40% {
//     transform: translateY($dist * 2) scale(1.3);
//     opacity: .9;
//   }
//   40% {
//     rx: $dist;
//     ry: $dist;
//     stroke-width: $stroke-reg;
//   }
//   45% {
//     rx: $dist + 5;
//     ry: $dist - 3;
//     stroke-width: $stroke-reg + 1;
//   }
//   55% {
//     rx: $dist;
//     ry: $dist;
//   }
// }

// @keyframes shadow {
//   45% {
//     opacity: .15;
//     rx: $dist;
//     ry: $dist - 7;
//     transform: translateY($dist - 5) scale(1.3);
//   }
// }


@media screen and (max-device-width: 480px) {
  .item-details {
    float: none !important;
    width: 50% !important;
    // margin-top: 15px !important;
  }
  .item-image-container {
    float: none !important;
    height: 100% !important;
    
  }
  .results-content {
    height: 100% !important;
  }
  .wrapper-order-pay {
    width: 100%;
    .order-items-ispaid {
      position: static;
    }
  }
}


@media screen and (max-width: 382px){
  .sweet-alert{
    .divbutton {
      padding: 5px 15px;
    }
  }

  .sweet-alert h2{
    font-size: 25px;
  }

  .sweet-alert p{
    font-size: 11px;
  }

  .sweet-alert .sa-icon{
    width: 70px;
    height: 70px;
  }

  .sweet-alert button{
    font-size: 12px;
  }

  .sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
    left: 9px;
  }

  .sweet-alert .sa-icon.sa-success .sa-line.sa-long {
    right: 3px;
  }
  .sweet-alert .sa-icon.sa-warning .sa-body {
    height: 38px;
  }
}

.head-well-lg{
  position: relative;
  text-align: center;
}

.well-well-lg{
   position: fixed;
   margin: auto;
   bottom: 0;
   width: 100%;
   height: 15%;
   background-color: #4a4a4a;
   padding: 5px;
} 

.dataCover{
   text-align:center;
}
.permissions{
   margin: auto;
}
.info {
   text-align: left;
} 

.cookie-ok-btn {
  float: right;
  position: absolute;
  right: 30px;
  top: 10px; 
}

.cookies-font {
  font-size: 1rem;
  color: white;
}

@media screen and (max-width: 382px){
  .cookies-font {
    font-size: .8rem;
  }
}
@media only screen and (max-width: 768px) and (min-width: 414px) { //iPad
  .well-well-lg {
    height: 10%;
  }
}
@media only screen and (max-width: 768px) {
  .cookies-font {
    font-size: .8rem;
  }
}
