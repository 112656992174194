.set-reservation-info {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  flex-wrap: wrap;
  margin-bottom: 5px;
  &::before, &::after {
    content: none;
  }
  label {
    margin: 0 !important;
    padding-top: 5px;
    padding-bottom: 10px;
  }
}

.wrapper-item-paid {
  position: relative;
  .text-is-paid {
    position:absolute;
    top: 0;
    left:-90px;
    span {
      font-weight:500;
      font-size:16px;
    }
  }
}