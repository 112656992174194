/*** GENERAL ***/
.line-divider {
  height: px2rem(1px) ;
  background-color: $light-grey;
}

.slogan-banner {
    background-color: $green;
    color: $white;
    padding: px2rem(15px);
    cursor: default;
}

.slogan-banner h3 {
    font-size: px2rem(21px);
    color: $white;
    margin: 0;
}



.social-icon {
  margin-left: 10px;
  margin-top:5px;
}

.fb-share-button  {
  margin-top: 8px;
}

.error-container {
  height: 400px;
  max-width: 450px;
  display: flex;
  align-items: center;

}
.carousel-control {
  background: none !important;
}

.image-size {
  position: relative;
  padding-bottom: 52%;
  overflow: hidden;
  width: 100%;
}

.image-size-same{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

/* NAVIGATION */
/*header {
  top: 0;
  margin-bottom: -20px;
}*/
// .navbar {
//   background: $lighter-grey;
// }
.header-logo {
  margin: 0 .5em .5em 0;
  max-width: 200px;
}

.nav  i {
  color:$green;
  padding-left: 5px;
}
.navbar-header button {
  position: relative;
  background: none !important;
  border: none !important;
}
.dropdown open {
  border: none;
}

.navbar-header {
  float: none;
}
.navbar-toggle {
  float: right;
  display: block;
  margin-top: 25px;
  margin-bottom: 18px;
}

.icon-bar {
  background-color: $green !important;
}
.navbar-collapse.collapse {
  display: none!important;
}
.navbar-nav {
  float: none!important;
}
.navbar-nav>li {
  float: none;
}

.navbar-nav>li>a {
  font-size: px2rem(30px);
  font-weight: 600;
}

.navbar-nav .divider {
  height: px2rem(4px) ;
  background-color: $light-grey;
}

.navbar .loggedin-text {
  font-size: px2rem(30px);
  font-weight: 400;
  color: $black;
}

.navbar .header-loggedin {
  margin-top: 32px;
  margin-bottom: 18px;
  padding-right: 50px;
}

.navbar .profile-btn {
  margin-right: 10px;
}

.navbar-collapse.collapse.in{
  display:block !important;
}

/*** HOME PAGE CONTENT ***/

.reset-btn {
  background-color: #eee;
}
.reset-btn span {
  font-size: px2rem(26px);
  color: $red;
}
.location-container {
  margin-top: 6px;
}

.active-search-input {
  background-color: $green;
  color: $white !important;
  border-bottom: 1px solid $green;
  text-decoration: none !important;
}

#toMenuItem, #toRestaurant, #toPrivateRooms {
  display: block;
  border-top-right-radius: .5em;
  border-top-left-radius: .5em;
  text-decoration: none !important;
  padding-top: 4px;
}

.noLRPadding {
  padding-left: 0px;
  padding-right: 0px;
}

.search-container {
  padding-top: 30px;
}

.search-container .toMenuItem {
  padding: 14px;
}

.search-container .toRestaurant {
  padding: 14px;
}
.search-input-container {
  padding: 5px;
  margin: 0 auto;
}

.search-input-container input {
  display: block;
  width: 100%;
  border: none;
  padding: 16px;
  outline: none;
  font-size: 0.95em;
}

.alert-homepage-top {
  @extend .inline-flex-block;
  background: $red;
  width: 100%;
  height: 10vh;
  & p {
    padding: 5% 0;
    a {
      color: $white;
    }
  }

}
/*section {
&.secondCtaImage {
  height:px2rem(275px);
  background: url('../images/home_food2.png');
  background-size: cover;
  background-position: center;
  position: relative;
  &.secondCtaImage::before {
    content: attr(data-content);
    width:100%;
    color: $white;
    z-index:1;
    bottom:0;
    padding:4px 10px;
    text-align:center;
    font-size: px2rem(40px);
    font-weight: 400;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
  }
&.secondCtaImage::after {
    content: '';
    position: absolute;
    height:px2rem(275px);
    top: 0; bottom: 0;
    left: 0; right: 0;
    width: 100%;
    margin-left: 100% auto;
    margin-right: 0 auto;
    background:rgba(0,0,0,0.6);
    opacity: 1;
}
  & p{
    font-family: $lato;
    font-size: px2rem(45px);
    color:$white;
    margin: 0 auto;
  }
}
&.ctaImage {
  height:px2rem(275px);
  background: url('../images/make_food2.png');
  background-size: cover;
  background-position: center;
  & p{
    font-family: $lato;
    font-size: px2rem(45px);
    color:$white;
    margin: 0 auto;
  }
}
&.vprops2 {
  margin: px2rem(30px) auto;
  & .item {
    height: 165px;
    border: 1px solid rgba($grey, .2);
    margin: px2rem(10px) 0;
  }
}
&.vprop {
  & .item{
    margin: px2rem(30px) 0;
  }
}
}*/
.item .back {
  display: flex;
  align-items: center;
}
.item .back p {
  font-size: 12px;
}

.dropdown-menu {
  min-width:intial;
  width:100%;
}
#advancedSearch.btn-link {
  color:$green;
  &:hover {
    text-decoration: underline;
  }
}



input[type=range] {
  -webkit-appearance: none;
  width: 40%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 30%;
  height: 5.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px $grey, 0px 0px 1px $grey;
  background: $green;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0.9px 0.9px 1px $grey, 0px 0px 0.9px $grey;
  border: 1.8px solid #000;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  background: #f8ffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4.4px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: darken($green, 10)
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px $grey, 0px 0px 1px $grey;
  background: $green;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0.9px 0.9px 1px $grey, 0px 0px 0.9px $grey;
  border: 1.8px solid #000;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  background: #f8ffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 11.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: $green;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px $grey, 0px 0px 1px $grey;
}
input[type=range]::-ms-fill-upper {
  background: $green;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px $grey, 0px 0px 1px $grey;
}
input[type=range]::-ms-thumb {
  box-shadow: 0.9px 0.9px 1px $grey, 0px 0px 0.9px $grey;
  border: 1.8px solid $orange;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  background: #f8ffff;
  cursor: pointer;
  height: 11.4px;
}




/*** YELP RATINGS ***/


/*.yelp-logo-img {
  width: 40px;
}*/

.yelp-rating-stars img {
  margin: auto;
  // height: 12px;
  margin-right: 5px;
  width: auto;
}

.rating-text {
  width: 100px;
  margin: auto;
}

/**** MENU ITEM and RESTAURANT DETAILS ***/
.item-container {
  padding-left: px2rem(20px);
  padding-right: px2rem(20px);
}
#itemName, .venue-name {
  font-size: px2rem(25px);
}

.venue-item-info-container .venue-name:hover, .venue-name:focus {
  color: darken($green, 15);
}
.item-rating-container {
  margin-left: 10px;
  margin-top: 24px;
}

.item-venue-info h3 {
  font-size: px2rem(25px);
}

.menu-section-glyphicon {
  font-size: px2rem(16px);
}
.item-description p {
  font-size: px2rem(20px);
}
.item-gallery {
  display: block;
}
.item-gallery form button {
  margin: 0 auto;
}

/*.item-details-btn {
  margin-bottom: px2rem(6px);
  background: $green;
  color: $black;
  text-transform: uppercase;
  width: 100%;
  padding-bottom: px2rem(4px);
  border: 1px solid darken($green, 15);
}*/
/* I, oumou add this style bellow to make a margin between the picture and the submit a photo button using the the submitBon id*/

.item-details-btn:hover{
  background-color: lighten($green , 15);
  color: $white;
}

/*.item-details-btn-disabled {
  @extend .item-details-btn;
  background: $grey;
}*/

.item-details-btn-disabled:hover {

  background-color: lighten($grey , 15);
  color: $white;
}

.item-yelp-ratings-container {
  /*margin-top: px2rem(30px);*/
  margin-left: px2rem(10px);
  margin-top: 0;

}

.clicks-restraunt-views {
  margin-top: px2rem(33px);
  margin-left: px2rem(10px);
}

.fancytree-active .clicks-counter {
  color:$white;
}

.clicks-counter {
  font-size:12px !important;
  color:$black;
}
/*
.carousel {
  max-height: 250px !important;
}
.carousel-image {
  max-height: 150px !important;
}
.carousel-inner {
  height: 150px !important;
}
.carousel-inner .item {
  height: 150px !important;
}
*/
.item-venue-address {
  color: darken($green, 15)
}
.item-venue-address:hover {
  color: darken($green, 5)
}

/*** RATINGS VIEW ***/
.item-rating-stars-container {
  display: inline-flex;
}
.rating-item-name span {
  font-size: px2rem(24px);
}

.star-rating-desc {
  font-size: px2rem(20px);
  margin: 0;
  padding: 0;
}


.action {
  text-align: center;
}

.rating-input {
  display: inline-block;
}

.image {
  height: auto;
  width: auto;
}

.item-rating-price-container {
  display: inline-block;
  width: 280px;
}

.item-rating-price-container input {
  //text-indent: 6px;
}


.price {
  color: #02843d;
  font-size: 20px !important;
  background-color: transparent;
  border: 1px solid #ccc;
  text-align: right;
  min-width: 50px;
  border-bottom: 1px solid #ccc !important;
  box-shadow: none;
  padding:0;
}

.price:focus {
  box-shadow: none !important;
  outline: 0 none;
}
.price-tag {
  font-size: px2rem(20px);
  color: $green;
}

.price-tag2 {
  font-size: px2rem(20px);
  color: #02843d;
}

.flat-slider-group {
  text-align: right;
}

.flat-slider-group > * {
  display: inline-block;
}

.text-review {
  width: 100%;
  resize: none;
  border: none;
  background-color: #fff;
}

.portion-size {
  text-align: center;
}

.selected {
  color: rgb(0,0,0);
}

.unselected {
  //Force override to the background color that's being used in BYOB btn
  background: $light-grey !important;
  color: rgb(0, 0, 0) !important;
  border: 1px solid $grey;
}

.text-na {
  text-align: center;
}

.text-quantities {
  font-size: 12px;
}

.review-rating-stars {
    padding-left: 5px;
    margin-left: -50px;
    margin-top: 20px;
}

.item-name {
    letter-spacing: 1px;
    padding-right: 10px;
}

.price-input {
  padding-left: 3px;
  padding-right: 3px;
  text-align: left;
  max-width: 72px;
  text-indent: 5px;
}

.value-price-slider-label-right {
  font-size: 10px;
  text-align: right;
  float: right;
}

.value-price-slider-label-left {
  font-size: 10px;
  text-align: left;
  float: left;
}

.toggle-expert-review {
  font-weight: bold;
}

/** REGISTRATION */
.registration-message-container {
  margin-top: 60px;
  max-width: 500px;
}

/** ORDER CHECKOUT **/
.order-checkout-totals {
  display:flex;
  justify-content:flex-end;
}

.order-total-divider {
  height: 2px;
  border: none;
  color: $grey;
  background-color: $light-grey;
}

//Fix for Issue 2916: Reducing the gap in filters

#buttonfilter{
  margin-top: 0px;
  margin-bottom: 0px;
}

/*** MEDIA QUERIES ***/
/*
*
* Style for media screen sizes more than 800px
*
*/

@media screen and (min-device-width: 769px) {
    .price-input {
       // max-width: 50px;
        text-indent: 2px;
    }

    .price-tag {
        top: 7px;
    }

    .price-tag2 {
        top: 0;
        left: 0;
    }

    #userValuePrice {
        margin-left: -10px;
    }

}

//Override  styles on bigger screen sizes
@media only screen and (min-width: 769px) {
  #itemName .venue-name{
  font-size: px2rem(30px);
  }

  .item-venue-info h3 {
    font-size: px2rem(30px);
  }

  .menu-section-glyphicon {
    font-size: px2rem(16px);
  }
  .item-description p {
    font-size: px2rem(22px);
  }
  .navbar .dropdown-loggedin {
    display: none;
  }

  .item-container {
    margin-top: 10px;
    position: relative;
    min-height: 310px;
    height: auto;
    
  }
  .details-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 50%;
    .item-menu {
      display: block;
      height: 20px;
    }
    .item-description {
      padding-top: 5px;
      .wrapper-btn-desc {
        align-items: start !important;
        .btn-desc {
          padding: 0;
          margin-top: 5px;
        }
      }
    }
    p {
      margin: 0;
      padding: 0;
      float: left;
    }
  }
  .item-venue-address {
    max-width: 50%;
    display: block;
  }
  .item-gallery {
    position: absolute;
    width: 50%;
    left: 50%;
    top: 2px;
  }

  .venue-gallery {
    margin-bottom: 10px;
  }
 /* .carousel-image {
    max-height: 230px !important;
  }
  .carousel-inner {
    height: 230px !important;
  }
  .carousel-inner .item {
    max-height: 230px !important;
  }*/
  .item-description p{
    width: 50%;
    margin: 0 auto;
    padding-right: 20px;
  }

  .venue-location {
    display: block;

    & span {
      font-size: px2rem(24px);
    }
  }

  .rating-item-name span {
    font-size: px2rem(30px);
  }
  .rating-username {
    font-size: px2rem(28px);
    width: 50%;
  }
  .price {
    padding-right: 0;
  }
  .price-text {
    font-size: px2rem(26px);
  }
  .price-tag {
    left: 40px;
}
}

@media only screen and (max-width: 415px) {
  .wrapper-table-custom {
    overflow: scroll;
  }
}

/** Devices screen < 768px */
@media only screen and (max-width: 768px) {
  #notify-warning-reservation {
    .wrapper-list-notify-methods  {
      width: 100% !important;
    }
  }
  .flex-end-btn-warning {
    width: 100% !important;
  }
  .warning-minuteAhead-item {
    background: #fff;
  }
  .navbar .header-loggedin {
      display: none;
  }
  //Fixes some issues displaying on safari
   .vprops2 .item {
    min-width: px2rem(225px) !important;
  }
  .item .back p{
     font-size: px2rem(18px);
  }

  .clicks-counter {
    display: block;
  }
  .wrapper-search-rating {
    display: flex;
    flex-direction: column;
  }
  .set-reservation-info {
    label {
      padding-top: 0 !important;
    }
  }
  .min-text-slider {
    padding-right: 0 !important;
  }
  .max-text-slider {
    padding-left: 0 !important;
  }
  .wrapper-item-paid {
    position: relative;
    .text-is-paid {
      position: static !important;
      span {
        font-weight:500;
        font-size:16px;
      }
    }
  }
  .server-overall-rating-slider {
    flex-direction: column !important;
  }
  .delivery-overall-review {
    .slider-text {
      width: 0;
    }
  }
}

.venue-feature-btn {
  font-size: 14px !important;
}

.natext-column {
  padding: 0 !important;
}

.patent {
  color: $orange;
}

.accord a {
  color: $white;
}

/*
*
* Style for media screen sizes less than 320px
*
*/

@media screen and (max-device-width: 320px) {
    .review-rating-stars {
        margin-left: 10px;
        margin-top: 20px;
    }

    .vpros2-row-container {
      margin-left: -30px !important;
    }

    .venue-feature-btn {
      font-size: 12px !important;
    }
}

// Order checkout
.order-items-ispaid {
  position:absolute;
  left:-90px;
  font-weight:500;
  font-size:16px;
  top:-32px;
}

.flex-row-cart {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .wrapper-item-info {
    display: flex;
    flex-direction: row;
  }
}

.flex-expert-review {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &::before {
    content: none;
  }
  &:after {
    content: none;
  }
}

@media screen and (max-width: 480px) {
  #toMenuItem, #toRestaurant, #toPrivateRooms {
    display: block;
    border-top-right-radius: .5em;
    border-top-left-radius: .5em;
    text-decoration: none !important;
    padding-top: 4px;
    font-size:12px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
