$browser-context: 16; // Default

@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

@function px2rem($pixels, $context: $browser-context) {
    $value :strip-unit($pixels);
    $base : strip-unit($context);
    @if $value == 0 {
	@return $value;
    }
    @return ($value/$base) * 1rem;
}
