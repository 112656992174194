//All Imports
//@import url('https://fonts.googleapis.com/css?family=Lato|Open+Sans');

//typography
$open: 'Open Sans', sans-serif;
$lato: 'Lato', sans-serif;

// font size
$base-font: 16px;

//colors version 1
/*$green: rgba(60,179,113,1.0);  //#3cb371
$blue: rgba(60,143,179,1.0);  //#3c8fb3
$orange: rgba(245,166,35,1.0);  //#F6A623
$black:  rgba(74,74,74,1.0); //#4A4A4A
$grey: rgba(149,157,153,1.0); //#959D99
$light-grey: rgba(189,198,193,1.0); //#BDC6C1
$lighter-grey: rgba(232,236,234,1.0); //#E8ECEA
$lightest-grey: rgba(241,244,242,1.0); //#F1F4F3
$white: rgba(255,255,255,1.0); //#fffff
$red: rgb(179, 0, 0); //TODO: update red color to RGBA
$brown: rgb(139,69,19);
$yellow: rgb(102,102,0);
$dark-green: rgb(0, 51, 0);
$mint-cream: rgb(239,255,250); //EFFFFA
$coral: rgb(255,147,79); //#FF934F*/

$green: rgba(2,132,61,1.0);  //#02843D
$blue: rgba(60,143,1790,1.0);  //#3c8fb3
$orange: rgba(252, 143, 63, 1); //#FC8F3F
$black:  rgba(74,74,74,1.0); //#4A4A4A
$grey: rgb(112,112,112); //#707070
$light-grey: rgb(189,198,193); //#BDC6C1
$lighter-grey: rgb(232,236,234); //#E8ECEA
$lightest-grey: rgba(241,244,242,1.0); //#F1F4F3
$white: rgba(255,255,255,1.0); //#fffff
$red: rgb(179, 0, 0); //TODO: update red color to RGBA
$brown: rgb(139,69,19);
$yellow: rgb(102,102,0);
$dark-green: rgb(0, 51,0);
$mint-cream: rgb(239,255,250); //EFFFFA
$coral: rgb(255,147,79); //#FF934F
$slider-selection: rgb(0,132,61); //#02843d

/** animation **/
$colors: $green, $green, $green, $green, $green;
$left: 25px;
$count: 40px;
$timing: 600ms infinite ease-in-out;
$delay: 75ms;

$stroke-reg: 3px;
$dist: 10px;
