/*$green: rgba(60,179,113,1.0);  //#3cb371
$blue: rgba(60,143,179,1.0);  //#3c8fb3
$orange: rgba(245,166,35,1.0);  //#F6A623
$black:  rgba(74,74,74,1.0); //#4A4A4A
$grey: rgba(149,157,153,1.0); //#959D99
$light-grey: rgba(189,198,193,1.0); //#BDC6C1
$lighter-grey: rgba(232,236,234,1.0); //#E8ECEA
$lightest-grey: rgba(241,244,242,1.0); //#F1F4F3
$white: rgba(255,255,255,1.0); //#fffff
$red: rgb(179, 0, 0); //TODO: update red color to RGBA
$brown: rgb(139,69,19);
$yellow: rgb(102,102,0);
$dark-green: rgb(0, 51, 0);
$mint-cream: rgb(239,255,250); //EFFFFA
$coral: rgb(255,147,79); //#FF934F*/
/** animation **/
html {
  overflow: scroll;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

.orange {
  color: #fc8f3f;
}

.orange:hover {
  color: #02843d;
}

.black {
  color: #4a4a4a;
}

.red {
  color: #b30000;
}

.yellow {
  color: #666600;
}

.dark-orange {
  color: #d25b03;
}

.brown {
  color: brown;
}

.centerBlock {
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: left;
}

.dark-green {
  color: #003300;
}

.center-flex-block {
  display: flex;
  justify-content: center;
}

.inline-flex-block, .alert-homepage-top {
  display: inline-flex;
  justify-content: center;
}

.flex-menu-content {
  width: 50%;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.inline-flex-block.dressCode, .dressCode.alert-homepage-top {
  display: block;
  justify-content: center;
}

.btn-green {
  background-color: #02843d;
  color: white;
  margin-left: 2px;
  margin-right: 2px;
}

.btn-green:hover {
  background-color: #03ac50;
  color: white;
}

.btn-red {
  background-color: #b30000;
  color: white;
  margin-left: 2px;
  margin-right: 2px;
}

.btn-red:hover {
  background-color: #dc0000;
  color: white;
}

.btn-red:active {
  background-color: #dc0000;
  color: white;
}

.xs-text {
  font-size: 10px;
}

.sm-text {
  font-size: 14px;
}

.md-text {
  font-size: 18px;
}

.lg-text {
  font-size: 22px;
}

.extra-padding {
  padding: 10px 16px;
}

.no-padding {
  padding: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-left {
  padding-left: 0;
}

.flex-block {
  display: flex;
}

.totally-center-block {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  -webkit-justify-content: center;
  /* Safari 6.1+ */
  -webkit-align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.float-right {
  float: right;
}

.full-width-btn {
  width: 100%;
  padding: 12px 0;
}

.section-btn {
  background-color: #02843d;
  color: white;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 1px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
}

.search-input {
  -webkit-box-sizing: "border-box";
  -moz-box-sizing: "border-box";
  box-sizing: "border-box";
  height: 32px;
  width: 100%;
  border: 1px solid #bdc6c1;
  padding: 10px 16px;
  outline: 0;
  font-size: 0.95em;
  margin-bottom: 0.75rem;
}

.search-input:focus, .login-input:focus {
  border: 1px solid #bdc6c1;
  background: white;
}

/*.btn-primary {
  color: $white !important;
  background: $green !important;
} */
.btn-primary {
  color: #fff;
  background-color: #02843D;
  border-color: #02843D;
  /* border-radius:50px !important; */
  padding: 8px;
}

.sweet-alert button {
  background-color: #02843d;
}

.sweet-alert button.cancel {
  background-color: #707070;
}

.sweet-alert button.confirm {
  background-color: #02843d;
}

.color-green .sweet-alert button.cancel {
  background-color: #02843d;
}

.swal2-icon.swal2-info {
  color: #02843d !important;
  border-color: #02843d !important;
}

.raterater-rating-layer .fa-star {
  color: #fc8f3f !important;
}

.rating-green-star .fa-star {
  color: #02843d !important;
}

.raterater-hover-layer .fa-star {
  color: #02843d !important;
}

.slider-selection, .tick-slider-selection, .in-selection {
  background: #00843d !important;
}

.custom-range-slider, .tick-slider-selection, .in-selection {
  background: #00843d !important;
}

.slider-handle {
  background: #fff !important;
}

/* loading dots */
@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.loading-dots {
  color: #02843d;
}

.loading-dots span {
  color: #02843d;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loading-dots span:nth-child(2) {
  animation-delay: .2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: .4s;
}

.loading-dots-md {
  font-size: 0px;
  line-height: 15px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 999;
}

.loading-dots-md span {
  font-size: 50px;
  line-height: 0px;
}

.bar {
  height: 18px;
  background: #02843d;
}

.default-btn-green {
  background-color: mediumseagreen;
  background-image: none;
  color: #fff;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 1px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 320px) {
  .portion-size-align {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 375px) and (min-width: 320px) {
  .portion-size-align {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 412px) and (min-width: 375px) {
  .portion-size-align {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 414px) and (min-width: 412px) {
  .portion-size-align {
    padding-left: 37px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 414px) {
  .portion-size-align {
    padding-left: 38px;
  }
}

@media only screen and (max-width: 768px) {
  .flex-menu-content {
    width: 100%;
    padding-right: 0;
  }
}

.price-control3 {
  border: none;
  width: 97%;
  display: inline-block !important;
}

.to-left-parentItems {
  padding-left: 17px !important;
}

.fa-caret-down:before {
  font-size: x-large;
}

.fa-caret-right:before {
  font-size: x-large;
}

.form-actions {
  margin: 0;
  background-color: transparent;
  text-align: center;
}

.ios-logo-header {
  margin-top: 20px;
  margin-left: 20px;
  max-width: 200px;
}

.m-bottom {
  margin-bottom: 7px !important;
}

.d-flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.sensory-naright-text {
  position: relative;
  margin-top: 5px;
}

.sensory-naright-text .preference-items {
  margin-left: 20px;
}

/* Loading bars */
@media screen and (max-device-width: 480px) {
  .item-details {
    float: none !important;
    width: 50% !important;
  }
  .item-image-container {
    float: none !important;
    height: 100% !important;
  }
  .results-content {
    height: 100% !important;
  }
  .wrapper-order-pay {
    width: 100%;
  }
  .wrapper-order-pay .order-items-ispaid {
    position: static;
  }
}

@media screen and (max-width: 382px) {
  .sweet-alert .divbutton {
    padding: 5px 15px;
  }
  .sweet-alert h2 {
    font-size: 25px;
  }
  .sweet-alert p {
    font-size: 11px;
  }
  .sweet-alert .sa-icon {
    width: 70px;
    height: 70px;
  }
  .sweet-alert button {
    font-size: 12px;
  }
  .sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
    left: 9px;
  }
  .sweet-alert .sa-icon.sa-success .sa-line.sa-long {
    right: 3px;
  }
  .sweet-alert .sa-icon.sa-warning .sa-body {
    height: 38px;
  }
}

.head-well-lg {
  position: relative;
  text-align: center;
}

.well-well-lg {
  position: fixed;
  margin: auto;
  bottom: 0;
  width: 100%;
  height: 15%;
  background-color: #4a4a4a;
  padding: 5px;
}

.dataCover {
  text-align: center;
}

.permissions {
  margin: auto;
}

.info {
  text-align: left;
}

.cookie-ok-btn {
  float: right;
  position: absolute;
  right: 30px;
  top: 10px;
}

.cookies-font {
  font-size: 1rem;
  color: white;
}

@media screen and (max-width: 382px) {
  .cookies-font {
    font-size: .8rem;
  }
}

@media only screen and (max-width: 768px) and (min-width: 414px) {
  .well-well-lg {
    height: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .cookies-font {
    font-size: .8rem;
  }
}

/*** GENERAL ***/
.line-divider {
  height: 0.0625rem;
  background-color: #bdc6c1;
}

.slogan-banner {
  background-color: #02843d;
  color: white;
  padding: 0.9375rem;
  cursor: default;
}

.slogan-banner h3 {
  font-size: 1.3125rem;
  color: white;
  margin: 0;
}

.social-icon {
  margin-left: 10px;
  margin-top: 5px;
}

.fb-share-button {
  margin-top: 8px;
}

.error-container {
  height: 400px;
  max-width: 450px;
  display: flex;
  align-items: center;
}

.carousel-control {
  background: none !important;
}

.image-size {
  position: relative;
  padding-bottom: 52%;
  overflow: hidden;
  width: 100%;
}

.image-size-same {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* NAVIGATION */
/*header {
  top: 0;
  margin-bottom: -20px;
}*/
.header-logo {
  margin: 0 .5em .5em 0;
  max-width: 200px;
}

.nav i {
  color: #02843d;
  padding-left: 5px;
}

.navbar-header button {
  position: relative;
  background: none !important;
  border: none !important;
}

.dropdown open {
  border: none;
}

.navbar-header {
  float: none;
}

.navbar-toggle {
  float: right;
  display: block;
  margin-top: 25px;
  margin-bottom: 18px;
}

.icon-bar {
  background-color: #02843d !important;
}

.navbar-collapse.collapse {
  display: none !important;
}

.navbar-nav {
  float: none !important;
}

.navbar-nav > li {
  float: none;
}

.navbar-nav > li > a {
  font-size: 1.875rem;
  font-weight: 600;
}

.navbar-nav .divider {
  height: 0.25rem;
  background-color: #bdc6c1;
}

.navbar .loggedin-text {
  font-size: 1.875rem;
  font-weight: 400;
  color: #4a4a4a;
}

.navbar .header-loggedin {
  margin-top: 32px;
  margin-bottom: 18px;
  padding-right: 50px;
}

.navbar .profile-btn {
  margin-right: 10px;
}

.navbar-collapse.collapse.in {
  display: block !important;
}

/*** HOME PAGE CONTENT ***/
.reset-btn {
  background-color: #eee;
}

.reset-btn span {
  font-size: 1.625rem;
  color: #b30000;
}

.location-container {
  margin-top: 6px;
}

.active-search-input {
  background-color: #02843d;
  color: white !important;
  border-bottom: 1px solid #02843d;
  text-decoration: none !important;
}

#toMenuItem, #toRestaurant, #toPrivateRooms {
  display: block;
  border-top-right-radius: .5em;
  border-top-left-radius: .5em;
  text-decoration: none !important;
  padding-top: 4px;
}

.noLRPadding {
  padding-left: 0px;
  padding-right: 0px;
}

.search-container {
  padding-top: 30px;
}

.search-container .toMenuItem {
  padding: 14px;
}

.search-container .toRestaurant {
  padding: 14px;
}

.search-input-container {
  padding: 5px;
  margin: 0 auto;
}

.search-input-container input {
  display: block;
  width: 100%;
  border: none;
  padding: 16px;
  outline: none;
  font-size: 0.95em;
}

.alert-homepage-top {
  background: #b30000;
  width: 100%;
  height: 10vh;
}

.alert-homepage-top p {
  padding: 5% 0;
}

.alert-homepage-top p a {
  color: white;
}

/*section {
&.secondCtaImage {
  height:px2rem(275px);
  background: url('../images/home_food2.png');
  background-size: cover;
  background-position: center;
  position: relative;
  &.secondCtaImage::before {
    content: attr(data-content);
    width:100%;
    color: $white;
    z-index:1;
    bottom:0;
    padding:4px 10px;
    text-align:center;
    font-size: px2rem(40px);
    font-weight: 400;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
  }
&.secondCtaImage::after {
    content: '';
    position: absolute;
    height:px2rem(275px);
    top: 0; bottom: 0;
    left: 0; right: 0;
    width: 100%;
    margin-left: 100% auto;
    margin-right: 0 auto;
    background:rgba(0,0,0,0.6);
    opacity: 1;
}
  & p{
    font-family: $lato;
    font-size: px2rem(45px);
    color:$white;
    margin: 0 auto;
  }
}
&.ctaImage {
  height:px2rem(275px);
  background: url('../images/make_food2.png');
  background-size: cover;
  background-position: center;
  & p{
    font-family: $lato;
    font-size: px2rem(45px);
    color:$white;
    margin: 0 auto;
  }
}
&.vprops2 {
  margin: px2rem(30px) auto;
  & .item {
    height: 165px;
    border: 1px solid rgba($grey, .2);
    margin: px2rem(10px) 0;
  }
}
&.vprop {
  & .item{
    margin: px2rem(30px) 0;
  }
}
}*/
.item .back {
  display: flex;
  align-items: center;
}

.item .back p {
  font-size: 12px;
}

.dropdown-menu {
  min-width: intial;
  width: 100%;
}

#advancedSearch.btn-link {
  color: #02843d;
}

#advancedSearch.btn-link:hover {
  text-decoration: underline;
}

input[type=range] {
  -webkit-appearance: none;
  width: 40%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 30%;
  height: 5.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #707070, 0px 0px 1px #707070;
  background: #02843d;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0.9px 0.9px 1px #707070, 0px 0px 0.9px #707070;
  border: 1.8px solid #000;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  background: #f8ffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4.4px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #015226;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #707070, 0px 0px 1px #707070;
  background: #02843d;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0.9px 0.9px 1px #707070, 0px 0px 0.9px #707070;
  border: 1.8px solid #000;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  background: #f8ffff;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 11.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #02843d;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #707070, 0px 0px 1px #707070;
}

input[type=range]::-ms-fill-upper {
  background: #02843d;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #707070, 0px 0px 1px #707070;
}

input[type=range]::-ms-thumb {
  box-shadow: 0.9px 0.9px 1px #707070, 0px 0px 0.9px #707070;
  border: 1.8px solid #fc8f3f;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  background: #f8ffff;
  cursor: pointer;
  height: 11.4px;
}

/*** YELP RATINGS ***/
/*.yelp-logo-img {
  width: 40px;
}*/
.yelp-rating-stars img {
  margin: auto;
  margin-right: 5px;
  width: auto;
}

.rating-text {
  width: 100px;
  margin: auto;
}

/**** MENU ITEM and RESTAURANT DETAILS ***/
.item-container {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

#itemName, .venue-name {
  font-size: 1.5625rem;
}

.venue-item-info-container .venue-name:hover, .venue-name:focus {
  color: #01391a;
}

.item-rating-container {
  margin-left: 10px;
  margin-top: 24px;
}

.item-venue-info h3 {
  font-size: 1.5625rem;
}

.menu-section-glyphicon {
  font-size: 1rem;
}

.item-description p {
  font-size: 1.25rem;
}

.item-gallery {
  display: block;
}

.item-gallery form button {
  margin: 0 auto;
}

/*.item-details-btn {
  margin-bottom: px2rem(6px);
  background: $green;
  color: $black;
  text-transform: uppercase;
  width: 100%;
  padding-bottom: px2rem(4px);
  border: 1px solid darken($green, 15);
}*/
/* I, oumou add this style bellow to make a margin between the picture and the submit a photo button using the the submitBon id*/
.item-details-btn:hover {
  background-color: #03cf60;
  color: white;
}

/*.item-details-btn-disabled {
  @extend .item-details-btn;
  background: $grey;
}*/
.item-details-btn-disabled:hover {
  background-color: #969696;
  color: white;
}

.item-yelp-ratings-container {
  /*margin-top: px2rem(30px);*/
  margin-left: 0.625rem;
  margin-top: 0;
}

.clicks-restraunt-views {
  margin-top: 2.0625rem;
  margin-left: 0.625rem;
}

.fancytree-active .clicks-counter {
  color: white;
}

.clicks-counter {
  font-size: 12px !important;
  color: #4a4a4a;
}

/*
.carousel {
  max-height: 250px !important;
}
.carousel-image {
  max-height: 150px !important;
}
.carousel-inner {
  height: 150px !important;
}
.carousel-inner .item {
  height: 150px !important;
}
*/
.item-venue-address {
  color: #01391a;
}

.item-venue-address:hover {
  color: #026b31;
}

/*** RATINGS VIEW ***/
.item-rating-stars-container {
  display: inline-flex;
}

.rating-item-name span {
  font-size: 1.5rem;
}

.star-rating-desc {
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}

.action {
  text-align: center;
}

.rating-input {
  display: inline-block;
}

.image {
  height: auto;
  width: auto;
}

.item-rating-price-container {
  display: inline-block;
  width: 280px;
}

.price {
  color: #02843d;
  font-size: 20px !important;
  background-color: transparent;
  border: 1px solid #ccc;
  text-align: right;
  min-width: 50px;
  border-bottom: 1px solid #ccc !important;
  box-shadow: none;
  padding: 0;
}

.price:focus {
  box-shadow: none !important;
  outline: 0 none;
}

.price-tag {
  font-size: 1.25rem;
  color: #02843d;
}

.price-tag2 {
  font-size: 1.25rem;
  color: #02843d;
}

.flat-slider-group {
  text-align: right;
}

.flat-slider-group > * {
  display: inline-block;
}

.text-review {
  width: 100%;
  resize: none;
  border: none;
  background-color: #fff;
}

.portion-size {
  text-align: center;
}

.selected {
  color: black;
}

.unselected {
  background: #bdc6c1 !important;
  color: black !important;
  border: 1px solid #707070;
}

.text-na {
  text-align: center;
}

.text-quantities {
  font-size: 12px;
}

.review-rating-stars {
  padding-left: 5px;
  margin-left: -50px;
  margin-top: 20px;
}

.item-name {
  letter-spacing: 1px;
  padding-right: 10px;
}

.price-input {
  padding-left: 3px;
  padding-right: 3px;
  text-align: left;
  max-width: 72px;
  text-indent: 5px;
}

.value-price-slider-label-right {
  font-size: 10px;
  text-align: right;
  float: right;
}

.value-price-slider-label-left {
  font-size: 10px;
  text-align: left;
  float: left;
}

.toggle-expert-review {
  font-weight: bold;
}

/** REGISTRATION */
.registration-message-container {
  margin-top: 60px;
  max-width: 500px;
}

/** ORDER CHECKOUT **/
.order-checkout-totals {
  display: flex;
  justify-content: flex-end;
}

.order-total-divider {
  height: 2px;
  border: none;
  color: #707070;
  background-color: #bdc6c1;
}

#buttonfilter {
  margin-top: 0px;
  margin-bottom: 0px;
}

/*** MEDIA QUERIES ***/
/*
*
* Style for media screen sizes more than 800px
*
*/
@media screen and (min-device-width: 769px) {
  .price-input {
    text-indent: 2px;
  }
  .price-tag {
    top: 7px;
  }
  .price-tag2 {
    top: 0;
    left: 0;
  }
  #userValuePrice {
    margin-left: -10px;
  }
}

@media only screen and (min-width: 769px) {
  #itemName .venue-name {
    font-size: 1.875rem;
  }
  .item-venue-info h3 {
    font-size: 1.875rem;
  }
  .menu-section-glyphicon {
    font-size: 1rem;
  }
  .item-description p {
    font-size: 1.375rem;
  }
  .navbar .dropdown-loggedin {
    display: none;
  }
  .item-container {
    margin-top: 10px;
    position: relative;
    min-height: 310px;
    height: auto;
  }
  .details-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 50%;
  }
  .details-column .item-menu {
    display: block;
    height: 20px;
  }
  .details-column .item-description {
    padding-top: 5px;
  }
  .details-column .item-description .wrapper-btn-desc {
    align-items: start !important;
  }
  .details-column .item-description .wrapper-btn-desc .btn-desc {
    padding: 0;
    margin-top: 5px;
  }
  .details-column p {
    margin: 0;
    padding: 0;
    float: left;
  }
  .item-venue-address {
    max-width: 50%;
    display: block;
  }
  .item-gallery {
    position: absolute;
    width: 50%;
    left: 50%;
    top: 2px;
  }
  .venue-gallery {
    margin-bottom: 10px;
  }
  /* .carousel-image {
    max-height: 230px !important;
  }
  .carousel-inner {
    height: 230px !important;
  }
  .carousel-inner .item {
    max-height: 230px !important;
  }*/
  .item-description p {
    width: 50%;
    margin: 0 auto;
    padding-right: 20px;
  }
  .venue-location {
    display: block;
  }
  .venue-location span {
    font-size: 1.5rem;
  }
  .rating-item-name span {
    font-size: 1.875rem;
  }
  .rating-username {
    font-size: 1.75rem;
    width: 50%;
  }
  .price {
    padding-right: 0;
  }
  .price-text {
    font-size: 1.625rem;
  }
  .price-tag {
    left: 40px;
  }
}

@media only screen and (max-width: 415px) {
  .wrapper-table-custom {
    overflow: scroll;
  }
}

/** Devices screen < 768px */
@media only screen and (max-width: 768px) {
  #notify-warning-reservation .wrapper-list-notify-methods {
    width: 100% !important;
  }
  .flex-end-btn-warning {
    width: 100% !important;
  }
  .warning-minuteAhead-item {
    background: #fff;
  }
  .navbar .header-loggedin {
    display: none;
  }
  .vprops2 .item {
    min-width: 14.0625rem !important;
  }
  .item .back p {
    font-size: 1.125rem;
  }
  .clicks-counter {
    display: block;
  }
  .wrapper-search-rating {
    display: flex;
    flex-direction: column;
  }
  .set-reservation-info label {
    padding-top: 0 !important;
  }
  .min-text-slider {
    padding-right: 0 !important;
  }
  .max-text-slider {
    padding-left: 0 !important;
  }
  .wrapper-item-paid {
    position: relative;
  }
  .wrapper-item-paid .text-is-paid {
    position: static !important;
  }
  .wrapper-item-paid .text-is-paid span {
    font-weight: 500;
    font-size: 16px;
  }
  .server-overall-rating-slider {
    flex-direction: column !important;
  }
  .delivery-overall-review .slider-text {
    width: 0;
  }
}

.venue-feature-btn {
  font-size: 14px !important;
}

.natext-column {
  padding: 0 !important;
}

.patent {
  color: #fc8f3f;
}

.accord a {
  color: white;
}

/*
*
* Style for media screen sizes less than 320px
*
*/
@media screen and (max-device-width: 320px) {
  .review-rating-stars {
    margin-left: 10px;
    margin-top: 20px;
  }
  .vpros2-row-container {
    margin-left: -30px !important;
  }
  .venue-feature-btn {
    font-size: 12px !important;
  }
}

.order-items-ispaid {
  position: absolute;
  left: -90px;
  font-weight: 500;
  font-size: 16px;
  top: -32px;
}

.flex-row-cart {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-row-cart .wrapper-item-info {
  display: flex;
  flex-direction: row;
}

.flex-expert-review {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-expert-review::before {
  content: none;
}

.flex-expert-review:after {
  content: none;
}

@media screen and (max-width: 480px) {
  #toMenuItem, #toRestaurant, #toPrivateRooms {
    display: block;
    border-top-right-radius: .5em;
    border-top-left-radius: .5em;
    text-decoration: none !important;
    padding-top: 4px;
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.results-banner {
  margin-top: -20px;
  background-color: #02843d;
  color: white;
  padding: 0.9375rem;
}

.results-banner h3 {
  font-size: 1.3125rem;
  color: white;
  margin: 0;
}

.results-search-container {
  padding: 0.9375rem;
}

.results-search-container button {
  height: 3.75rem;
}

.results-search-container button:hover {
  background-color: #01391a !important;
}

.item-name {
  font-size: 1.375rem;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize !important;
}

.results-content {
  margin: 10px auto;
  padding: 0.625rem 0.625rem;
  min-height: 16.5625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/*.item-details {
    padding-left: px2rem(10px);
    float: right;
    width: 60%;
    min-height: 100%;
    margin: 0 auto;
}*/
.item-details h3 {
  padding: 0;
  padding-right: 0.3125rem;
  margin: 0;
  font-size: 1.125rem;
}

.item-detail-column {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.item-image-container {
  height: 100%;
  width: 40%;
}

.item-image-container a {
  display: block;
  width: 100%;
  height: 100%;
}

.itemImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-inner > .item > a > img, .carousel-inner > .item > img, .img-responsive, .thumbnail a > img, .thumbnail > img {
  object-fit: cover;
}

.item-description {
  word-wrap: break-word;
  display: inline-block;
  font-size: 1.25rem;
}

.winedata1 {
  display: block;
}

.addBtn1, .addBtn2 {
  display: inline-block;
  font-size: 18px;
  border: 2px solid #666666;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 190%;
  margin-left: 5px;
}

.wineType1, .wineType2, .wineType3 {
  display: inline-block;
  font-size: 18px;
  border: 2px solid #666666;
  line-height: 190%;
  padding-left: 5px;
  padding-right: 5px;
  width: 300px;
  margin-top: 5px;
}

.wineType2 {
  margin-left: 20px;
}

.wineType3 {
  margin-top: 5px;
  margin-left: 40px;
}

.addBtn1:hover, .addBtn2:hover {
  cursor: pointer;
  background-color: mediumseagreen;
  color: white;
  border: 2px solid white;
}

.wineType1:hover, .wineType2:hover, .wineType3:hover {
  cursor: pointer;
}

.plusMinus {
  float: right;
  size: 24px;
  text-align: right;
  padding-right: 5px;
  color: #888888;
}

.hidden-item-desc {
  display: none;
}

.venue-distance label {
  margin-top: 0;
}

/*
*   RESTAURANT RESULTS
*/
#venue-name {
  font-size: 1.25rem;
  padding-right: 0.9375rem;
}

#venue-name:hover {
  color: #eb6604;
}

.venue-address {
  font-size: 1.25rem;
  margin-bottom: 0;
  padding-bottom: 0;
}

.view-venue-btn {
  /* background-color: $green;
    color: $white;
    text-transform: uppercase;*/
  font-size: 15px !important;
  margin-top: 1rem;
}

.view-venue-btn:hover {
  background-color: #03b654;
  color: white;
}

.venue-distance label, .venue-distance span {
  font-size: 1.25rem;
}

.venue-phone {
  font-size: 1.25rem;
}

.rating-text {
  font-size: 1.125rem;
}

/*
*   Styles on bigger screen size
*/
@media only screen and (min-width: 769px) {
  .results-banner h3 {
    font-size: 1.5rem;
  }
  .results-wrapper {
    width: 70%;
    margin: 0 auto;
    max-width: 1000px;
  }
  .results-content {
    min-height: 21.25rem;
  }
  /*.item-details {
      padding-top: px2rem(20px);
      padding-bottom: px2rem(20px);
      width: 50%;
      text-align: center;
      overflow: hidden;
  }*/
  .item-description {
    width: 100%;
  }
  .item-detail-column {
    display: inline-block;
  }
  .item-image-container {
    width: 50%;
  }
  .item-ratings-container {
    padding-top: 0.625rem;
    margin: 0 auto;
    width: 25%;
  }
  .venue-distance label, .venue-distance span {
    font-size: 1.25rem;
  }
  .venue-phone, .rating-text, .venue-address, .item-description {
    font-size: 1.25rem;
  }
  #venue-name, .item-name {
    font-size: 1.5rem;
  }
}

.searchItemResultStyle {
  background: none !important;
  color: mediumseagreen;
  position: absolute;
  padding-left: 160px !important;
}

.yelp-rating-count {
  color: mediumseagreen;
  display: inline-block;
}

.google-text-count {
  padding-right: 5px;
  font-size: 14px;
  color: #f5a623;
  vertical-align: middle;
}

.wrapper-btn-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-desc {
  background: transparent;
  border: none;
  font-size: 10px;
  color: #3cb371;
  font-style: italic;
  cursor: pointer;
  display: inline-block;
  padding: none;
}

.btn-desc:hover {
  text-decoration: underline;
}

.btn-desc:focus {
  outline: none !important;
}

/*
*   General Accordions Views Styles on
*   menu item and restaurant details
*/
.accordion-heading {
  display: block;
}

.text-quantities {
  font-size: 12px;
  width: 40px;
  text-align: center;
}

.guru-presenation-text-margin {
  padding-left: 16px;
}

/** HIGHEST RATED MENU ITEMS **/
.star-rating-count {
  margin-top: -3px;
  font-size: 12px;
}

/*** RESTAURANT MENU ***/
.menu-item-rating-container {
  display: inline-flex;
}

.rated-star-icon {
  color: #fc8f3f;
}

.not-rated-star-icon {
  color: #707070;
}

.menu-filtering-text,
.hr-menu-filtering-text {
  display: none;
}

span.restaurant-menu-item {
  color: #02843d;
  text-decoration: none;
}

ul.restaurant-menu-addon-instructions {
  margin: 0;
}

ul.restaurant-menu-addon-instructions li {
  color: #fc8f3f;
  font-size: 1.1rem;
}

.fancytree-ext-table input {
  color: #000;
  position: static !important;
  margin: 0;
  padding: 0;
}

.fancytree-ext-table input[type="number"] {
  font-size: 1.1rem;
  min-width: 4rem;
}

.fancytree-ext-table label {
  color: #fc8f3f;
  font-size: 1.1rem;
}

.fancytree-ext-table .fancytree-checkbox-placeholder {
  display: inline-block;
  margin-left: 0.5em;
  width: 1em;
}

.restaurant-menu-btn {
  padding: 10px 12px;
  font-size: 1.25rem;
  text-transform: uppercase;
  background-color: #02843d;
  color: white;
}

.restaurant-menu-btn:hover {
  background-color: #03cf60;
  color: white;
}

.menu-filter-btn {
  font-size: 1.25rem;
  border: 1px solid #02843d;
  text-transform: uppercase;
}

/*.goto-item-btn {
  background-color: $green;
  color: $white;
  margin-right: 20px;
}*/
.goto-item-btn {
  width: 80px !important;
}

#tree, #tree-menu {
  margin-left: 5px;
  outline: none;
  box-shadow: none !important;
  position: relative;
}

.fancytree-title {
  font-size: 15px !important;
  margin-top: 0;
}

.restaurant-menu-item, .menu-item-price {
  display: flex;
  font-size: 1.5rem !important;
}

.restaurant-menu-item p, .menu-item-price p {
  font-size: 1.5rem !important;
  margin-right: 4px;
}

.restaurant-menu-item, .item-discount {
  display: flex;
  font-size: 1.5rem !important;
}

.restaurant-menu-item p, .item-discount p {
  font-size: 1.375rem !important;
  margin-right: 4px;
}

.fancytree-selected {
  background: #f1f4f2 !important;
  border: none !important;
}

tr.fancytree-active td {
  background: none !important;
  color: unset !important;
}

tr.fancytree-active td span.fancytree-title {
  color: unset !important;
}

.fancytree-treefocus {
  border: none !important;
}

.fancytree-treefocus:active {
  border: none !important;
}

table.fancytree-ext-table {
  border-collapse: none !important;
}

/* Define custom width and alignment of fancy tree table columns */
#tree tr td:nth-of-type(1), #tree-menu tr td:nth-of-type(1) {
  color: #fc8f3f;
}

.table-tree tr td:nth-of-type(1) {
  color: mediumseagreen;
}

.orange-filter {
  background-color: #eb6604 !important;
  color: white !important;
}

.orange-filter .fancytree-title span {
  color: white !important;
}

.orange-filter span,
.orange-filter .flavor-match i,
.orange-filter .menu-item-name,
.orange-filter .flavor-match p {
  color: white !important;
}

.green-filter {
  background-color: green !important;
  color: white !important;
}

.green-filter .fancytree-title span {
  color: white !important;
}

.green-filter span,
.green-filter .flavor-match i,
.green-filter .menu-item-name,
.green-filter .flavor-match p {
  color: white !important;
}

.grey-filter {
  background-color: lightgrey !important;
  color: #ff934f !important;
}

.grey-filter .fancytree-title span {
  color: black !important;
}

#dietary-restrictions-labels {
  display: block;
  margin-left: 10px;
}

/*** ITEM REVIEWS ***/
.reviews-media-object {
  width: 120px;
}

.reviews-media-body {
  padding-top: 10px;
}

/** ADDITIOANAL RESTAURANT DETAILS **/
/* Custom dropdown */
.venue-awards-container .custom-dropdown {
  margin: 0px 100px 0px 100px;
}

.custom-dropdown {
  position: relative;
  display: block;
  vertical-align: middle;
  margin: 10px;
}

.custom-dropdown select {
  background-color: #f5a623;
  color: #fff;
  padding: 0.625rem;
  border: 0;
  margin-right: 0;
  border-radius: 3px;
  text-indent: 0.01px;
  text-overflow: '';
  width: 60px;
  -webkit-appearance: button;
  /* hide default arrow in chrome OSX */
  margin-top: 4px;
}

.custom-dropdown option {
  font-size: .9em;
  border: 0;
  margin: 0;
  border-radius: 3px;
  width: 70px;
  /* hide default arrow in chrome OSX */
}

.custom-dropdown::before,
.custom-dropdown::after {
  content: "";
  position: absolute;
  pointer-events: none;
}

.custom-dropdown::after {
  /*  Custom dropdown arrow */
  content: "\25BC";
  height: 1em;
  font-size: .7em;
  line-height: 1;
  right: 1.2em;
  top: 50%;
  margin-top: -.5em;
}

.custom-dropdown.payment::after {
  content: "";
  display: none;
}

.custom-dropdown select[disabled] {
  color: rgba(0, 0, 0, 0.9);
}

.custom-dropdown select[disabled]::after {
  color: rgba(0, 0, 0, 0.9);
}

.custom-dropdown::before {
  background-color: rgba(0, 0, 0, 0.15);
}

.custom-dropdown::after {
  color: rgba(0, 0, 0, 0.4);
  content: "";
}

#no-hours-container {
  display: none;
}

#no-venue-hours-btn {
  background-color: #02843d;
  color: white;
}

.display-hours-container {
  margin-top: 4px;
  margin-left: 6px;
  padding-right: 0 !important;
  font-size: 0.875rem;
}

.edit-hours-container {
  display: none;
}

.hours-week-day, #openText, #toText {
  font-size: 1.125rem;
}

.after-hours-container {
  display: none;
}

.edit-venue-hours {
  padding: 0;
  font-size: 12px;
  margin-top: 12px;
}

#openText, #toText {
  display: inline-block;
  font-size: 1rem;
  margin: 0;
}

/** Modified Save hours button **/
.save-hours-btn {
  background-color: #02843d;
  color: white;
  text-transform: uppercase;
  font-size: 1.75rem;
}

/**  Delete hours button; CSS; Gets applied to all restaurants **/
.delete-hours-btn {
  background-color: #d9534f;
  color: white;
  text-transform: uppercase;
  font-size: 1.75rem;
  margin-left: 12.7rem;
}

#sav-del-container {
  margin-left: 1rem;
  margin-top: 2rem;
}

.save-corkage-btn {
  height: 30%;
  width: auto;
  font-size: 11px;
}

#saveCapacity {
  height: 30%;
  width: auto;
  font-size: 11px;
  display: none;
}

.seating-capacity-container {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 70px;
  justify-content: center;
}

.venue-awards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cart-stars-container {
  margin-top: 5px;
}

.cart-item-details span {
  font-size: 18px;
}

.cart-item-details p {
  padding-left: 2px;
  padding-right: 2px;
  margin-bottom: 0px;
  font-size: 18px;
}

.group-cart-addons {
  margin-bottom: 5px;
  display: inline-block;
  width: 100%;
}

.group-cart-addons .text-addon {
  font-size: 12px;
}

.inline-input-icon {
  position: relative;
  font-size: 16px;
}

.inline-input-icon input {
  text-indent: 10px;
}

.inline-input-icon span {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 16px;
  line-height: 25px;
}

.inline-input-icon span.percent {
  right: 10px;
  left: unset;
}

.inline-input-icon input.percent {
  text-indent: unset;
  padding-right: 30px;
}

input.percent::-webkit-outer-spin-button,
input.percent::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.percent {
  -moz-appearance: textfield;
}

.delivery-tip-btn {
  padding: 4px 1px;
  font-size: 14px;
}

#edit-item-container .new-item-quantity {
  width: 30px;
  padding: 2px;
  text-align: center;
  margin: 0 4px;
  border-radius: 5px;
  border-color: #bdc6c1;
}

.item-quantity {
  color: #000;
}

.item-name {
  color: black;
}

#custom-tip-amt, #custom-tip-per-amt {
  max-width: 220px;
}

#cart-items-totals {
  display: flex;
  justify-content: flex-end;
  background-color: rgba(2, 132, 61, 0.1);
  line-height: normal;
  padding-top: 12px;
}

/*** MEDIA QUERIES ***/
@media only screen and (min-width: 769px) {
  .custom-dropdown {
    font-size: 1em;
  }
  .custom-dropdown select {
    min-width: 80px;
  }
  #toText {
    display: inline-block;
  }
  #openText {
    display: inline-block;
  }
}

@media screen and (max-width: 320px) {
  .custom-dropdown select {
    height: 3rem;
    width: 5rem;
    padding: 0;
    margin: 0;
  }
  .custom-dropdown select.day-hours-select {
    font-size: 1.25rem;
    padding: 0.75rem;
  }
  .save-hours-btn {
    font-size: 1.5rem;
  }
  .delete-hours-btn {
    margin-left: 5rem;
    font-size: 1.5rem;
  }
  .hours-week-day {
    display: inline-block;
    font-size: 1.3rem;
  }
  h3.col-xs-12.col-sm-3.col-md-4 {
    font-size: 1.375rem;
    text-transform: uppercase;
  }
  .col-xs-12.col-sm-12.col-md-5 {
    margin-top: -20px;
  }
}

@media screen and (min-width: 321px) and (max-width: 420px) {
  .custom-dropdown select {
    height: 3rem;
    width: 6rem;
    padding: 0;
    margin: 0;
    padding-left: 4px;
  }
  .custom-dropdown select.day-hours-select {
    font-size: 1.25rem;
    padding: 0.75rem;
  }
  .save-hours-btn {
    font-size: 1.5rem;
  }
  .delete-hours-btn {
    font-size: 1.5rem;
  }
  .hours-week-day {
    display: inline-block;
    font-size: 1.3rem;
  }
  h3.col-xs-12.col-sm-3.col-md-4 {
    font-size: 1.375rem;
    text-transform: uppercase;
  }
  .col-xs-12.col-sm-12.col-md-5 {
    margin-top: -20px;
  }
}

.clickSafari {
  cursor: pointer;
}

.iconInfo {
  display: inline-block;
}

.iconInfo span {
  font-size: 15px;
  text-decoration: none;
  color: #3cb371;
  top: 5px;
}

.tooltip {
  min-width: 300px;
  position: fixed;
  z-index: 9999 !important;
}

.tooltip-inner {
  background-color: #aeb1b7;
  color: black;
  cursor: pointer;
  font-size: 14px;
  margin-top: 0px !important;
}

.col-xs-2.col-sm-5.col-md-5.natext {
  font-size: 11px;
}

.collapse-restraunt-additional-details .collapse-body {
  background-color: #ffffff;
  padding: 15px;
}

/*
*   Profile View Styles
*/
/*** DIETARY PREFERENCES ***/
.preference-columns {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  height: auto;
}

.preference-items {
  display: inline;
  font-weight: normal;
}

.preference-items:hover {
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  .preference-columns {
    height: 420px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .preference-columns {
    height: 420px;
  }
}

@media screen and (min-width: 770px) and (max-width: 999px) {
  .preference-columns {
    height: 840px;
  }
}

/*** PAST REVIEWS ***/
.rating-img {
  max-width: 350px;
  max-height: 350px;
  margin: 0 auto;
}

.stars-container {
  max-width: 350px;
}

.edit-past-review {
  font-size: 1.3em;
}

.edit-past-review:hover {
  cursor: pointer;
}

.past-reviews-container {
  padding-top: 2px;
  padding-bottom: 5px;
  max-width: 350px;
  margin: 0 auto;
}

.center-flex-block .rating-details {
  margin-bottom: 0;
}

.center-flex-block .rated-item-price {
  padding-left: 4px;
}

.center-flex-block .rated-venue-name {
  padding-left: 4px;
}

.left-empty {
  border-color: red;
}

#snackbar {
  visibility: hidden;
  min-width: 70px;
  max-width: 140px;
  margin-left: -35px;
  color: white;
  text-align: center;
  font-family: "Lato",sans-serif;
  font-size: 1.25rem;
  padding: 16px;
  position: fixed;
  z-index: 10000;
  left: 80%;
  bottom: 30px;
  white-space: nowrap;
}

#snackbar.show-success {
  visibility: visible;
  background-color: mediumseagreen;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbar.show-error {
  visibility: visible;
  background-color: red;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-10 {
  padding: 10px;
}

.item-star-rated > .star-item-history > .raterater-rating-layer > .fa-star {
  color: #3cb371 !important;
}

.item-star-join-rated > .star-item-history > .raterater-rating-layer > .fa-star {
  color: #1b4aa9 !important;
}

.flex-title-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text-desc-bold {
  font-weight: bold;
}

#waitlist-notification-setting .flex-title-center h1, #waitstaff-notification-setting .flex-title-center h1 {
  font-weight: bold;
}

#waitlist-notification-setting .wrapper-checkboxes, #waitstaff-notification-setting .wrapper-checkboxes {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

#waitlist-notification-setting .wrapper-checkboxes label, #waitstaff-notification-setting .wrapper-checkboxes label {
  cursor: pointer !important;
  display: inline-block !important;
  position: relative;
}

#waitlist-notification-setting .wrapper-checkboxes label span, #waitstaff-notification-setting .wrapper-checkboxes label span {
  font-size: 15px !important;
  font-weight: bold;
}

#waitlist-notification-setting .wrapper-checkboxes label span::before, #waitstaff-notification-setting .wrapper-checkboxes label span::before {
  font-weight: bold !important;
}

#waitlist-notification-setting .wrapper-checkboxes label input[type='checkbox']:disabled ~ span::before, #waitstaff-notification-setting .wrapper-checkboxes label input[type='checkbox']:disabled ~ span::before {
  color: #aaa !important;
}

#waitlist-notification-setting #notify-warning-reservation, #waitstaff-notification-setting #notify-warning-reservation {
  margin-bottom: 20px;
  padding-left: 20px;
}

#waitlist-notification-setting #notify-warning-reservation .warning-notify-item, #waitstaff-notification-setting #notify-warning-reservation .warning-notify-item {
  margin: 15px 0;
}

#waitlist-notification-setting #notify-warning-reservation .warning-notify-item .warning-minuteAhead-item, #waitstaff-notification-setting #notify-warning-reservation .warning-notify-item .warning-minuteAhead-item {
  margin-right: 5px;
}

#waitlist-notification-setting #notify-warning-reservation .warning-notify-item .wrapper-list-notify-methods, #waitstaff-notification-setting #notify-warning-reservation .warning-notify-item .wrapper-list-notify-methods {
  display: block;
  width: 60%;
  margin-top: 10px;
}

#waitlist-notification-setting .check-notPutNoti, #waitstaff-notification-setting .check-notPutNoti {
  cursor: pointer !important;
  display: inline-block !important;
  position: relative;
}

#waitlist-notification-setting .check-notPutNoti span, #waitstaff-notification-setting .check-notPutNoti span {
  font-size: 15px !important;
  font-weight: bold;
}

#waitlist-notification-setting .check-notPutNoti span::before, #waitstaff-notification-setting .check-notPutNoti span::before {
  font-weight: bold !important;
}

#waitlist-notification-setting #btn-save-waitlist-notify, #waitstaff-notification-setting #btn-save-waitlist-notify {
  padding: 7px 30px !important;
  border-radius: 5px !important;
  border: none;
  font-size: 14px;
}

#waitlist-notification-setting #btn-save-waitlist-notify:focus, #waitstaff-notification-setting #btn-save-waitlist-notify:focus {
  outline: none;
  border: none;
  box-shadow: 3px 3px 5px 6px #ccc;
}

#waitlist-notification-setting #btn-save-waitlist-notify:disabled, #waitstaff-notification-setting #btn-save-waitlist-notify:disabled {
  background: #aaa !important;
}

#waitlist-notification-setting #btn-add-notify-warning, #waitstaff-notification-setting #btn-add-notify-warning {
  border-radius: 5px !important;
  border: none;
  padding: 7px 10px;
  margin-bottom: 20px;
  font-size: 14px;
}

#waitlist-notification-setting #btn-add-notify-warning:focus, #waitstaff-notification-setting #btn-add-notify-warning:focus {
  outline: none;
  border: none;
  box-shadow: 3px 3px 5px 6px #ccc;
}

#btn-add-warning-noti {
  border: none;
  border-radius: 5px;
  padding: 6px 25px;
}

#btn-add-warning-noti:focus {
  outline: none;
}

#btn-add-warning-noti:disabled {
  background: #aaa !important;
}

.flex-end-btn-warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
}

.flex-end-btn-warning .btn-remove-warning-noti {
  display: inline-block;
  padding: 3px 15px;
  background: red;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0 0 0;
}

.flex-end-btn-warning .btn-remove-warning-noti .remove-warning-item {
  color: #fff;
  font-size: 16px;
}

#btn-cancel-warning-noti {
  background: red;
  padding: 6px 14px;
  border: none;
  border-radius: 5px;
  margin-right: 15px;
}

#btn-cancel-warning-noti:focus {
  outline: none;
}

.login-container {
  max-width: 620px;
}

.parent-wrapper-container {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 111px);
}

@media (max-width: 767px) {
  .parent-wrapper-container {
    padding: 0 4px;
  }
}

.btn-sp-green {
  height: 45px;
  line-height: 22.5px;
  padding: 0 40px;
  color: #fff;
  font-size: 17px;
  background: #02843d;
}

.btn-sp-green:hover,
.btn-sp-green:focus {
  color: #fff;
  background: #01391a;
}

/*.btn-register {
      font-size: 16px;
    }

    .form-control {
      font-size: 15px;
      box-shadow: none;
    }*/
.form-control::-webkit-input-placeholder, input.referrer-email::-webkit-input-placeholder {
  color: #aaadb0;
}

.form-control::-moz-placeholder, input.referrer-email::-moz-placeholder {
  color: #aaadb0;
}

.form-control:-ms-input-placeholder, input.referrer-email:-ms-input-placeholder {
  color: #aaadb0;
}

.form-control::placeholder, input.referrer-email::placeholder {
  color: #aaadb0;
}

.form-control:focus, input.referrer-email:focus {
  box-shadow: inset 0 1px 1px #707070, 0 0 6px #02843d;
}

.view .header {
  padding: 34px 0;
}

.view .header,
.view .header a {
  font-weight: 300;
  font-size: 21px;
}

.view input[type="text"],
.view input[type="password"],
.view input[type="email"],
.view input[type="color"],
.view input[type="date"],
.view input[type="datetime"] .view input[type="datetime-local"],
.view input[type="email"],
.view input[type="month"],
.view input[type="number"],
.view input[type="range"],
.view input[type="search"],
.view input[type="tel"],
.view input[type="time"],
.view input[type="url"],
.view input[type="week"] {
  background-color: #f6f6f6;
  height: 45px;
}

.view a.forgot,
.view a.to-login {
  float: right;
  padding: 17px 0;
  font-size: 13px;
}

.view form button {
  display: block;
  margin-bottom: 25px;
  width: 100%;
}

.view form label {
  height: 45px;
  line-height: 45px;
  margin: 0px;
}

.box {
  box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #cacaca;
  border-radius: 3px;
  padding: 10px;
  background: white;
}

.sp-form .has-error,
.sp-form .has-error .help-block {
  color: #ec3e3e;
  font-weight: 600;
}

.sp-form .has-error input[type="text"],
.sp-form .has-error input[type="password"] {
  border-color: #ec3e3e;
}

.sp-form .form-group {
  margin-bottom: 21px;
}

.sp-form input[type="text"],
.sp-form input[type="password"] {
  position: relative;
}

.sp-form .help-block {
  font-size: 12px;
  position: absolute;
  top: 43px;
}

.verify-view .box {
  padding-bottom: 30px;
}

.verify-view .box .header {
  padding-bottom: 20px;
}

.unverified-view .box {
  padding-bottom: 30px;
}

.unverified-view .box .header {
  padding-bottom: 25px;
}

.login-view .box {
  background-color: #f6f6f6;
  padding: 0;
}

.login-view label {
  margin-bottom: 7px;
}

.login-view .header p {
  margin-top: 2em;
}

.login-view .email-password-area {
  background-color: white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

@media (min-width: 767px) {
  .login-view .email-password-area {
    padding: 0 30px;
  }
}

.login-view .email-password-area label {
  height: 14px;
  line-height: 14px;
}

.login-view .email-password-area input[type='checkbox'] {
  visibility: hidden;
}

.login-view .email-password-area input[type='checkbox'] + label {
  position: relative;
  padding-left: 8px;
  line-height: 16px;
  font-size: 13px;
}

.login-view .email-password-area input[type='checkbox'] + label:after {
  position: absolute;
  left: -16px;
  width: 16px;
  height: 16px;
  border: 1px solid #cacaca;
  background-color: #f6f6f6;
  content: "";
}

.login-view .email-password-area input[type='checkbox']:checked + label:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowRTVBQUVGMzJEODBFMjExODQ2N0NBMjk4MjdCNDBCNyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0RTY4NUM4NURGNEYxMUUyQUE5QkExOTlGODU3RkFEOCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0RTY4NUM4NERGNEYxMUUyQUE5QkExOTlGODU3RkFEOCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQxNDQ4M0NEM0JERkUyMTE4MEYwQjNBRjIwMUNENzQxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkZDMEMxNjY2OUVCMUUyMTFBRjVDQkQ0QjE5MTNERDU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+3YY4qgAAALlJREFUeNpi/P//PwMlgImBQjDwBrCgCwQHB+NUfObMGT9mZuboe/fuheM0ABu4fv060/fv32cBNTNycHBE4nUBNs0/f/7cAWSeMzQ0rCA5DICaNwKj+qGRkVEFUYF47ty5GWfPns2EsjsYGRlFgM5OJzoQ//37t5eLi2sRMMDec3Jypn79+lVXX1//H9HRaGJisvr379/nuLm5lwKdP9vMzOwZyekAaEA3EF8G4hZCYcQ4mhcYAAIMAJGST/dDIpNQAAAAAElFTkSuQmCC);
  background-position: -1px -1px;
}

@media (min-width: 767px) {
  .login-view .email-password-area.small {
    border-right: 1px solid #cacaca;
  }
  .login-view .email-password-area.small .group-email {
    margin-bottom: 21px;
  }
}

@media (max-width: 767px) {
  .login-view .email-password-area.small {
    border-bottom: 1px solid #cacaca;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .box {
    max-width: 350px;
  }
}

.login-view .email-password-area.large {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

@media (min-width: 767px) {
  .login-view .email-password-area.large {
    padding: 0 50px;
  }
  .login-view .email-password-area.large .group-email label,
  .login-view .email-password-area.large .group-password label {
    height: 45px;
    line-height: 45px;
  }
}

.login-view .social-area {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0 20px;
  position: relative;
  padding-bottom: 20px;
  background-color: #f6f6f6;
}

.login-view .social-area .header {
  margin-bottom: -6px;
}

@media (max-width: 767px) {
  .login-view .social-area .header {
    padding: 0px;
  }
}

.login-view .social-area button {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.banner {
  display: block !important;
  margin: 0 auto;
  padding: 5px;
  max-width: 280px;
}

.login, .register {
  display: table;
}

.va-wrapper {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}

.loginBtn {
  display: inline-block !important;
  float: none !important;
}

/** STYLES for menuitems/add_menu_item.ejs **/
#menu_items .row {
  margin-right: 0;
}

#menu-item-stars {
  margin-top: 0.5em;
}

#addSubmitButton {
  /*font-size:1.3em;*/
}

.input-select-btn {
  float: right;
  font-size: 13px;
}

hr {
  color: #cccccc;
  border-style: solid;
}

.subh3 {
  font-size: 14px;
  margin-right: -5px;
}

.myInput {
  height: 50%;
  width: 40px;
  margin-top: 13px;
  margin-right: 10%;
}

.selected {
  opacity: 1;
  font-weight: bold;
  background-color: mediumseagreen;
}

.unselected {
  opacity: .35;
  font-weight: 100;
}

#picture-upload {
  border: 2px solid black;
}

#yelp-rating-stars {
  width: 50px;
}

/*#yelp-rating-stars img {
    margin: auto;
    height: 12px;
}*/
#yelp-review {
  margin-top: 2px;
  margin-left: 10px;
}

#yelp-logo-img {
  margin-top: -1px;
  margin-left: 2px;
  width: auto;
  height: 20px;
}

.deal-link {
  text-align: right;
}

.innerArea {
  max-height: 150px;
}

.add-to-list-btn {
  height: 50px;
  width: 300px;
  font-size: 25px;
}

#items-list {
  list-style-type: circle;
  margin-left: 20px;
}

.dropdown-container {
  width: 100%;
}

.custom-add-dropdown {
  width: 100%;
  padding: 6px;
  margin-bottom: 8px;
  margin-top: 2px;
  background-color: #fc8f3f;
  color: white;
  border: none;
  outline: none;
  font-size: 13px;
}

.custom-add-dropdown:focus {
  border: 1px solid #bdc6c1;
  border-radius: 2px;
}

.dropdown-option {
  font-size: 13px;
  background-color: #707070;
}

.select-title {
  font-size: 1.5rem;
}

.iconInfo {
  display: inline-block;
}

.iconInfo span {
  font-size: 18px;
  text-decoration: none;
  color: #3cb371;
}

.tooltip-inner {
  padding-top: 0rem !important;
  margin-top: -10rem;
  cursor: pointer;
  background-color: #aeb1b7;
}

#usersList .panel-default {
  max-width: 100%;
  overflow-x: scroll;
}

.metrics-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

.metrics-table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.metrics-table tr {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

.metrics-table th,
.metrics-table td {
  padding: .625em;
  text-align: center;
}

.metrics-table th {
  font-size: .85em;
  letter-spacing: .1em;
}

@media screen and (max-width: 600px) {
  .metrics-table {
    border: 0;
  }
  .metrics-table caption {
    font-size: 1.3em;
  }
  .metrics-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .metrics-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  .metrics-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  .metrics-table td:before {
    /*
    * aria-label has no advantage, it won't be read inside a .metrics-table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
  .metrics-table td:last-child {
    border-bottom: 0;
  }
}

.icon {
  width: 4.25rem;
}

.menuToggle {
  background-color: #02843d;
  color: white;
  text-decoration: none;
}

#toMenuItem, #toRestaurant, #toPrivateRooms {
  color: #02843d;
}

#toMenuItem:hover, #toRestaurant:hover, #toPrivateRooms:hover {
  text-decoration: underline;
}

.item {
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 1s;
  -moz-transition: -moz-transform 1s;
  -o-transition: -o-transform 1s;
  transition: transform 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
}

.item .cursorPointer {
  cursor: pointer !important;
}

.item .cursorDefault {
  cursor: default !important;
}

.non-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.item div {
  display: block;
  height: 100%;
  width: 100%;
  background: #bdc6c1;
  color: #4a4a4a;
  font-weight: bold;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.item .front {
  background: none;
}

.item .back {
  background: #02843d;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  position: relative;
  top: -16.5rem;
  height: 16.5rem;
}

.item .back p {
  color: white;
  padding: 1.25rem;
}

.item.flipped {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

/*textarea, input {
  padding:10px;
	font-family: FontAwesome, "Open Sans", Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}*/
textarea, input {
  padding: 0px;
}

/*normal: 400, semi-bold: 600, bold: 700; light: 300*/
/*COLORS:
Green (original):     #74c046;
Green darker:         #5d9d36;
Green light:          #d8eecb; (slider center)
Grey (original):      #566f7d;
Grey light:           #e5eaed;
Grey very light:      #f3f5f7;
Grey dark:            #41545f; (menu button type);
Grey dark:            #528a2f; (menu button border);
*/
/* Large desktops and laptops */
@media (min-width: 1200px) {
  .yesPaddingX {
    padding-left: 5em;
    padding-right: 5em;
  }
  .yesPaddingLeft {
    padding-left: 5em;
    padding-right: 5em;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .yesPaddingX {
    padding-left: 5em;
    padding-right: 5em;
  }
  .yesPaddingLeft {
    padding-left: 5em;
    padding-right: 5em;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .yesPaddingX {
    padding-left: 5em;
    padding-right: 5em;
  }
  .yesPaddingLeft {
    padding-left: 3em;
    padding-right: 3em;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .yesPaddingX {
    padding-left: 5em;
    padding-right: 5em;
  }
  .yesPaddingLeft {
    padding-left: 3em;
    padding-right: 3em;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .text-xs-left {
    text-align: left;
  }
  .text-xs-right {
    text-align: right;
  }
  .yesPaddingX {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .yesPaddingLeft {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.container-fluid {
  /*padding-left: 0;
    padding-right: 0;*/
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.borderRight {
  border-right: 2px solid;
}

.inBlock {
  display: inline-block;
}

.noPadding {
  padding: 0;
}

.half-m-t {
  margin-top: 0.5em;
}

.yesPaddingTop {
  padding-top: 1em;
}

.yesPaddingBottom {
  padding-bottom: 1em;
}

.yesMarginY {
  margin-top: 1em;
  margin-bottom: 1em;
}

.yesMarginY-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}

.yesMarginY-3 {
  margin-top: 3em;
  margin-bottom: 3em;
}

.yesMarginRight {
  margin-right: 1em;
}

.yesMarginX {
  margin-left: 1em;
  margin-right: 1em;
}

.padToCenter {
  padding-left: 23%;
}

.noPaddingX {
  padding-left: 0;
  padding-right: 0;
}

.editForAddRestDet {
  color: blue;
}

.alignCenter {
  text-align: center;
}

/***** HEADER *****/
.header-container {
  border: none;
  background-color: #fff;
  padding: 0;
  margin-top: 0;
  padding-top: 1em;
  box-sizing: border-box;
  max-height: 15%;
  width: 100%;
}

/*This is important.Do not erase*/
.header-container .row {
  margin-right: 0;
}

/*.header-container .banner {*/
/*text-align: left;*/
/*}*/
.logo {
  margin-left: 5%;
  max-width: 100px;
}

.logo img {
  width: 100%;
}

.banner {
  padding-left: 0;
  max-width: 210px;
}

.banner img {
  width: 100%;
}

.slogan {
  /*background-color: #74c046;*/
  background-color: #6EC1C2;
  font-size: 106%;
  letter-spacing: -0.01em;
  margin-top: 1em;
  padding: 0.7% 0 0.7% 0;
  text-align: center;
  text-transform: uppercase;
  word-spacing: 0.1em;
}

.slogan p {
  color: #000;
  font-weight: 600;
  line-height: 1.8em;
  margin: 0;
}

/***** PAGE CONTENT *****/
.page-content {
  background-color: inherit;
  padding-left: 0;
  padding-right: 0;
}

/*hr {
    border-top-color: #cccccc;
    margin: 0.5em 0;
}*/
/*h2,
h3 {
    color: #000;
}*/
/*h2.invitetext {
    margin-top: 14px;
    font-size: 26px;
    text-align: center;
}

h2 {
    margin-top: 14px;
    font-size: 26px;
}

h3 {
    font-size: 18px;
    font-weight: bold;
}

h4 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

h4.blurbtext {
    line-height: 150%;
    padding-left: 8px;
    padding-right: 8px;
}

h6 {
    margin-bottom: 0;
}

ul {
    padding: 0 0 0 0;
    list-style-type: none;
}

img {
    width: 100%;
    margin-top: .5em;
}
*/
/***** VIEW SINGLE ITEM *****/
.subBar {
  background-color: #d8eecb;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #41545f;
  padding: 10px 15px;
  text-transform: uppercase;
}

#bestHere li,
#bestHere li a {
  color: #5d9d36;
}

#viewTheDish {
  margin-bottom: 3%;
}

#viewTheDish img.theDish {
  max-width: 200px;
  float: left;
}

#viewTheDish h4 {
  font-size: 16px;
  margin-bottom: 0;
}

.yelpSmallStars {
  height: 26px;
  width: 100%;
  background-image: url("../images/stars_map@2x.png");
}

.yelpSmallStars0 {
  background-position: 522px 4222px;
}

.yelpSmallStars1half {
  background-position: 522px 4176px;
}

.yelpSmallStars4 {
  background-position: 522px 4056px;
}

img.yelpLogoImg {
  margin: 0 0 8px 0;
  width: auto;
}

#viewTopReview img {
  margin-top: 0;
  margin-bottom: 6px;
}

#viewTopReview h5 {
  color: #5d9d36;
  margin-top: 0;
  margin-bottom: 0;
}

#viewTopReview cite {
  color: #5d9d36;
  display: block;
  line-height: 1.1em;
  margin: 3px 0;
}

.reviewBody {
  color: #566f7d;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.reviewImage {
  padding-right: 0;
}

.reviewImageBest {
  max-height: 100px;
  overflow: hidden;
}

.reviewBox {
  -webkit-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #566f7d;
  color: #566f7d;
  float: right;
  margin-left: 6px;
}

.reviewBox td {
  border-right: 1px solid #566f7d;
  padding: 0 4px;
}

.bestHeader {
  margin-bottom: 10px;
}

#hours {
  margin-top: 0;
}

/***** map *****/
#viewMap {
  /*background-color: rgb(229,234,237);*/
  height: 20rem;
  margin: 2rem 0;
}

/***** FORM *****/
/*overrides bootstrap neg margin*/
.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}

input.referrer-email {
  width: 75%;
}

/*label,*/
.dropdown-label {
  margin-right: 8px;
  font-weight: 600;
  color: #000;
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  /*padding-left: 12px;*/
}

input[type="checkbox"] {
  margin-right: 4px;
}

button.dropdown-label {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 3px;
  width: 100%;
}

ul.dropdown-menu {
  width: 100%;
  /*padding-left: 45%;*/
}

.dropdown-header {
  color: #5d9d36;
  padding-left: 0px;
  font-size: 100%;
  font-weight: 600;
}

#distance-number,
#distanceIng-number {
  width: 50px;
  padding: 4px 4px 4px 6px;
  display: inline-block;
  height: 29px;
  margin-bottom: 6px;
}

#distanceIng-number {
  margin-right: 6px;
}

.input-group-inline,
.input-group-btn-inline {
  display: inline-block;
}

/***** DROPDOWNS, CHECKBOXES, SLIDERS, BUTTONS *****/
#sortByWhat .dropdown-menu {
  min-width: 0;
}

.dropdown-menu > li > a {
  padding: 3px 13px;
}

.checkbox-inline + .checkbox-inline {
  margin-left: 0;
}

.checkbox-inline:last-of-type {
  margin-right: 0;
}

.slider-selection, .tick-slider-selection, .in-selection {
  background: #02843d;
}

.slideDiv {
  color: #566f7d;
  display: table;
  font-weight: 700;
  width: 100%;
}

.sliderLabel:first-of-type {
  padding-right: 14px;
}

.sliderLabel:last-of-type {
  padding-left: 14px;
}

.checkbox-inline {
  font-size: 12px;
}

button[type="submit"] {
  background-color: #02843d;
  background-image: none;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 1px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
}

button[type="submit"]:hover {
  color: #ffffff;
  font-weight: 400;
  opacity: 0.9;
  font-size: 1em;
  text-shadow: none;
  text-transform: uppercase;
}

span.blueUnderline {
  color: blue;
  text-decoration: underline;
}

.submitDiv {
  text-align: center;
  padding: 10px 0 50px;
}

/**********/
#moreOptionsLink {
  text-align: center;
}

.moreCTA a {
  color: #566f7d;
  font-weight: 600;
}

.more {
  font-size: 12px;
}

.more label {
  font-size: 14px;
  line-height: 16px;
}

.more p {
  margin-bottom: 0;
}

.more p:last-of-type {
  margin-bottom: 10px;
}

span.moreLabel {
  font-weight: 600;
}

.info p {
  /*font-weight: */
}

#moreRestaurant,
#view_single_item_moreOptions,
#bestHere,
#viewTraditional {
  display: none;
}

span.labelCaps {
  text-transform: uppercase;
}

/***** BEST MENU ITEMS AT THIS RESTAURANT *****/
li.best {
  border-bottom: 1px solid;
  padding-bottom: 12px;
}

li.best:last-of-type {
  border-bottom: none;
}

.ellipses {
  /*word-wrap: break-word;*/
  height: 110px;
  margin-bottom: 0;
}

/***** font-awesome *****/
.fa-chevron-circle-down:before,
.fa-chevron-circle-right:before {
  color: #5d9d36;
  margin-left: 2px;
}

/***** star ratings ****/
/*.starsRow {
    margin: 6px 0 16px 0;
}*/
.starsDiv,
.starsDivHalf {
  padding: 0;
}

.starsDiv label,
.starsDivHalf label {
  margin: 0 0 5px 0;
  width: 100%;
  line-height: 16px;
}

.starsDiv i,
.starsDivHalf i {
  font-size: 24px;
  color: #74c046;
  letter-spacing: 1px;
  margin-top: 4px;
}

.starsDivHalf i {
  font-size: 18px;
}

.starsDivSmall {
  margin-bottom: -9px;
}

.starsDivSmall i {
  font-size: 14px;
}

.starsDiv h4 {
  margin-bottom: 2px;
  margin-top: 0;
}

.fa-fw {
  width: auto;
}

/***** FOOTER *****/
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e5eaed+0,e5eaed+100&amp;0+0,1+20 */
#footer {
  position: relative;
  left: 0px;
  bottom: 0px;
  height: 72px;
  padding-top: 30px;
  text-align: center;
  width: 100%;
  z-index: 9;
  background-color: #e5eaed;
  background: -moz-linear-gradient(top, rgba(229, 234, 237, 0) 0%, #e5eaed 30%, #e5eaed 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(229, 234, 237, 0)), color-stop(30%, #e5eaed), color-stop(100%, #e5eaed));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(229, 234, 237, 0) 0%, #e5eaed 30%, #e5eaed 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(229, 234, 237, 0) 0%, #e5eaed 30%, #e5eaed 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(229, 234, 237, 0) 0%, #e5eaed 30%, #e5eaed 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(229, 234, 237, 0) 0%, #e5eaed 30%, #e5eaed 100%);
  /* W3C */
  /*filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00e5eaed', endColorstr='#e5eaed', GradientType=0);*/
  /* IE6-9 */
}

.menu-button {
  /*background-color: #74c046;*/
  background-color: #6EC1C2;
  height: 42px;
  border-right: 2px solid #004447;
  padding: 11px 0 0 0;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
}

div.menu-button a {
  text-decoration: none;
  display: block;
  color: #111111;
}

div.menu-button a:hover {
  text-decoration: none;
  display: block;
  color: #C93413;
}

.menu-button:last-of-type {
  border-right: none;
}

.hyperlinks {
  color: #0B0080;
}

.itemImage img {
  width: 295px;
}

.greyText {
  color: #5e5e5e;
}

.font12 {
  font-size: 12px;
}

.verticalCenter {
  vertical-align: middle;
}

/* For profile modal */
.modal-backdrop.in {
  z-index: auto !important;
}

.init-hide {
  display: none;
}

.wrapper-reply-comment {
  margin-top: 15px;
}

.wrapper-reply-comment .user-info-reply {
  margin: 5px 0;
  font-size: 14px;
  color: #666666;
}

.wrapper-reply-comment .flex-btn-end {
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.wrapper-reply-comment .flex-btn-end .btn-reply-comment {
  border: none;
  border-radius: 5px;
  background: mediumseagreen;
  line-height: 25px;
  letter-spacing: normal;
  padding: 5px 25px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.wrapper-reply-comment .flex-btn-end .btn-reply-comment:hover {
  opacity: 0.85;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.wrapper-reply-comment .flex-btn-end .btn-reply-comment:focus {
  outline: none !important;
}

.wrapper-reply-comment .flex-btn-end .btn-reply-comment span {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}

.view-more-comments {
  color: #0085ff;
  font-size: 11px;
  cursor: pointer;
  text-decoration: underline;
}

.view-more-comments:hover {
  text-decoration: underline;
}

.item-comment {
  margin-top: 10px;
}

.item-comment .user-comment-info span {
  font-size: 11px;
  font-style: italic;
  color: #666666;
}

.item-comment .form-edit-comment .wrapper-btn-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
}

.item-comment .form-edit-comment .wrapper-btn-edit .btn-cancel-edit {
  margin-right: 15px;
  border: none;
  border-radius: 5px;
  background: #d74e4a;
  letter-spacing: normal;
  line-height: 25px;
  padding: 5px 25px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.item-comment .form-edit-comment .wrapper-btn-edit .btn-cancel-edit:hover {
  opacity: 0.85;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.item-comment .form-edit-comment .wrapper-btn-edit .btn-cancel-edit:focus {
  outline: none !important;
}

.item-comment .form-edit-comment .wrapper-btn-edit .btn-submit-edit {
  border: none;
  border-radius: 5px;
  letter-spacing: normal;
  background: mediumseagreen;
  padding: 5px 25px;
  line-height: 25px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.item-comment .form-edit-comment .wrapper-btn-edit .btn-submit-edit:hover {
  opacity: 0.85;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.item-comment .form-edit-comment .wrapper-btn-edit .btn-submit-edit:focus {
  outline: none !important;
}

.item-comment .form-edit-comment .wrapper-btn-edit .btn-submit-edit span {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}

.item-comment .editor-comment {
  color: #0085ff;
  cursor: pointer;
  margin-left: 10px;
  font-size: 11px;
  font-style: italic;
  text-decoration: underline;
}

/** Classes for special countered list, such as in Terms of Service **/
.list-section {
  counter-reset: section;
  list-style-type: none;
  *list-style-type: decimal;
}

.list-section > li {
  position: relative;
}

.list-section > li::before {
  position: absolute;
  left: -2em;
}

.list-subsection {
  counter-reset: subsection;
  list-style-type: none;
  *list-style-type: decimal;
}

.list-subsection > li {
  position: relative;
}

.list-subsection > li::before {
  position: absolute;
  left: -2.5em;
}

.list-subsubsection {
  counter-reset: subsubsection;
  list-style-type: none;
  *list-style-type: decimal;
}

.list-subsubsection > li {
  position: relative;
}

.list-subsubsection > li::before {
  position: absolute;
  left: -3.5em;
}

.list-section li::before {
  content: counter(section) ". ";
  counter-increment: section;
}

.list-subsection li::before {
  content: counter(section) "." counter(subsection) ". ";
  counter-increment: subsection;
}

.list-subsubsection li::before {
  content: counter(section) "." counter(subsection) "." counter(subsubsection) ". ";
  counter-increment: subsubsection;
}

.set-reservation-info {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.set-reservation-info::before, .set-reservation-info::after {
  content: none;
}

.set-reservation-info label {
  margin: 0 !important;
  padding-top: 5px;
  padding-bottom: 10px;
}

.wrapper-item-paid {
  position: relative;
}

.wrapper-item-paid .text-is-paid {
  position: absolute;
  top: 0;
  left: -90px;
}

.wrapper-item-paid .text-is-paid span {
  font-weight: 500;
  font-size: 16px;
}

.flex-row-res {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-res select {
  padding: 0 !important;
  max-width: 60px;
}

.server-past-rating {
  padding-top: 30px;
}

.wrapper-flatslider {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.calendar-style {
  cursor: pointer;
  font-size: 20px !important;
  margin-left: 10px;
  position: absolute;
  top: 5px;
  right: 0;
}

.server-overall-rating-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.server-overall-rating-slider .title-slider {
  margin: 0;
  font-size: 15px;
  font-weight: bold;
}

.server-overall-rating-slider .slider-text {
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
}

.flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#list-waitstaff-container .title-handle span {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}

.no-review-text {
  color: red;
  font-size: 12px;
  font-style: italic;
  display: inline-block;
  margin: 10px 0;
  border: 1px solid red;
  padding: 3px 15px;
  border-radius: 2px;
}

.overall-no-review {
  background: #999999 !important;
  opacity: 0.75;
}

.content-overall-rating {
  margin-bottom: 20px;
}

.content-overall-rating .server-overall-rating {
  padding: 20px 0 20px 15px;
  border-radius: 5px;
  border: 1px solid #999999;
  background: #9999994a;
}

.wrapper-has-bathroom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wrapper-has-bathroom label {
  cursor: pointer;
  margin: 0 !important;
}

.wrapper-has-bathroom label .label-text {
  color: #333333 !important;
  font-size: 14px !important;
}

.wrapper-has-bathroom label .label-text::before {
  font-size: 20px !important;
}

.average-list-sliders {
  padding: 20px 0 20px 15px;
  border-radius: 5px;
  border: 1px solid #999999;
  background: #9999994a;
}

.customer-review .default-text-reason {
  font-size: 14px;
  margin-top: 10px;
}

.customer-review .customer-comment {
  border: 3px solid #666;
  border-radius: 5px;
  padding: 15px;
  font-size: 14px;
  font-weight: normal;
  color: #333;
}

.wrapper-repl-server-comment .server-name-reply {
  font-size: 14px;
  font-style: italic;
  color: #666;
  font-weight: normal;
  margin: 0;
}

.wrapper-repl-server-comment .title-comment {
  font-size: 12px;
  font-style: italic;
  color: #999;
  margin-bottom: 5px;
}

.wrapper-repl-server-comment .reply-content {
  border: 2px solid #666;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.wrapper-exclude-rating {
  display: flex;
  flex-direction: row;
}

.wrapper-exclude-rating .exclude-rating {
  margin-left: 20px;
}

.wrapper-exclude-rating .exclude-rating label {
  position: relative;
}

.wrapper-exclude-rating .exclude-rating label input {
  font-size: 20px;
}

.wrapper-exclude-rating .exclude-rating label input:disabled ~ span {
  color: #999999 !important;
}

.wrapper-exclude-rating .exclude-rating label input:disabled ~ span::before {
  color: #999999 !important;
}

.wrapper-exclude-rating .exclude-rating label span {
  cursor: pointer;
  font-size: 14px !important;
  color: #333333 !important;
}

.wrapper-exclude-rating .exclude-rating label span::before {
  font-size: 16px !important;
  font-weight: bold !important;
}

.sliders-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 20px 0;
  justify-content: space-between;
}

.delivery-overall-review, .delivery-content-rating, .delivery-list-slider {
  border: 1px solid #999;
  background: #9999994a;
  padding: 30px 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

#waitlistOrders #btn-save-restAdmin-waitlist-settings {
  border: none;
  padding: 7px 20px;
  border-radius: 5px;
}

#waitlistOrders #btn-save-restAdmin-waitlist-settings:focus {
  outline: none;
}

#waitlistOrders .flex-title-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#waitlistOrders .item-rule-text {
  font-size: 15px;
  color: #333;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#waitlistOrders .item-rule-text .setTime-seated {
  margin-left: 5px;
}

#waitlistOrders .item-rule-text .small-input-val {
  margin: 0 10px 0 5px;
}

#waitlistOrders .item-rule-text .item-checkbox-added-waitlist {
  display: inline-block !important;
}

#waitlistOrders .small-input-val {
  max-width: 75px;
  border: 1px solid #aaa;
  padding: 2px 0;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-radius: 3px;
  border-radius: 1.25rem;
}

#waitlistOrders .item-checkbox-added-waitlist {
  display: block;
}

#waitlistOrders .item-checkbox-added-waitlist label {
  cursor: pointer;
}

#waitlistOrders .item-checkbox-added-waitlist label .label-text {
  font-size: 15px !important;
  font-weight: normal;
  color: #333333;
}

#waitlistOrders .item-checkbox-added-waitlist label .label-text::before {
  font-size: 18px !important;
  font-weight: bold;
}

#waitlistOrders #requirements-added-waitlist, #waitlistOrders #requirements-seated-waitlist {
  margin-left: 20px;
}

.wrapper-table-custom .table-custom {
  border: 2px solid #666666;
}

.wrapper-table-custom .table-custom thead {
  border-bottom: 2px solid #666666;
}

.wrapper-table-custom .table-custom thead tr td {
  border-right: 2px solid #666666;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  background: #aaaaaa;
}

.wrapper-table-custom .table-custom thead tr td:last-child {
  border-right: none;
}

.wrapper-table-custom .table-custom tbody tr {
  border-bottom: 2px solid #666666;
}

.wrapper-table-custom .table-custom tbody tr td {
  border-right: 2px solid #666666;
  text-align: center;
  font-size: 14px;
  padding: 5px 15px;
}

.wrapper-table-custom .table-custom tbody tr td:last-child {
  border-right: none;
}

.wrapper-table-custom .table-custom tbody tr td .icon-remove-time, .wrapper-table-custom .table-custom tbody tr td .icon-edit {
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 15px;
  padding-top: 2px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.wrapper-table-custom .table-custom tbody tr td .icon-add {
  width: 30px;
  height: 20px;
  color: #fff;
  font-size: 15px;
  padding-top: 2px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.wrapper-table-custom .table-custom tbody tr:nth-child(2n) {
  background: #ffffff;
}
