    .login-container {
      max-width: 620px;
    }
    .parent-wrapper-container {
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: calc(100vh - 111px);
    }

    
    @media (max-width: 767px) {
      .parent-wrapper-container {
        padding: 0 4px;
      }
    }

    .btn-sp-green {
      height: 45px;
      line-height: 22.5px;
      padding: 0 40px;
      color: #fff;
      font-size: 17px;
      background:$green;
    }

    .btn-sp-green:hover,
    .btn-sp-green:focus {
      color: #fff;
      background: darken($green, 15);
    }

    /*.btn-register {
      font-size: 16px;
    }

    .form-control {
      font-size: 15px;
      box-shadow: none;
    }*/

    .form-control::-webkit-input-placeholder {
      color: #aaadb0;
    }

    .form-control::-moz-placeholder {
      color: #aaadb0;
    }

    .form-control:-ms-input-placeholder {
      color: #aaadb0;
    }

    .form-control::placeholder {
      color: #aaadb0;
    }

    .form-control:focus {
      box-shadow: inset 0 1px 1px $grey, 0 0 6px $green;
    }

    .view .header {
      padding: 34px 0;
    }

    .view .header,
    .view .header a {
      font-weight: 300;
      font-size: 21px;
    }

    .view input[type="text"],
    .view input[type="password"],
    .view input[type="email"],
    .view input[type="color"],
    .view input[type="date"],
    .view input[type="datetime"]
.view input[type="datetime-local"],
    .view input[type="email"],
    .view input[type="month"],
    .view input[type="number"],
    .view input[type="range"],
    .view input[type="search"],
    .view input[type="tel"],
    .view input[type="time"],
    .view input[type="url"],
    .view input[type="week"] {
      background-color: #f6f6f6;
      height: 45px;
    }

    .view a.forgot,
    .view a.to-login {
      float: right;
      padding: 17px 0;
      font-size: 13px;
    }

    .view form button {
      display: block;
      margin-bottom: 25px;
      width:100%;
    }

    .view form label {
      height: 45px;
      line-height: 45px;
      margin: 0px;
    }

    .box {
      // margin-top: 10px;
      box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0.1);
      border: 1px solid #cacaca;
      border-radius: 3px;
      padding: 10px;
      background: $white;
    }

    .sp-form .has-error,
    .sp-form .has-error .help-block {
      color: #ec3e3e;
      font-weight: 600;
    }

    .sp-form .has-error input[type="text"],
    .sp-form .has-error input[type="password"] {
      border-color: #ec3e3e;
    }

    .sp-form .form-group {
      margin-bottom: 21px;
    }

    .sp-form input[type="text"],
    .sp-form input[type="password"] {
      position: relative;
    }

    .sp-form .help-block {
      font-size: 12px;
      position: absolute;
      top: 43px;
    }

    .verify-view .box {
      padding-bottom: 30px;
    }

    .verify-view .box .header {
      padding-bottom: 20px;
    }

    .unverified-view .box {
      padding-bottom: 30px;
    }

    .unverified-view .box .header {
      padding-bottom: 25px;
    }

    .login-view .box {
      background-color: #f6f6f6;
      padding: 0;
    }

    .login-view label {
      margin-bottom: 7px;
    }

    .login-view .header p {
      margin-top: 2em;
    }

    .login-view .email-password-area {
      background-color: white;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    @media (min-width: 767px) {
      .login-view .email-password-area {
        padding: 0 30px;
      }
    }

    .login-view .email-password-area label {
      height: 14px;
      line-height: 14px;
    }

    .login-view .email-password-area input[type='checkbox'] {
      visibility: hidden;
    }

    .login-view .email-password-area input[type='checkbox'] + label {
      position: relative;
      padding-left: 8px;
      line-height: 16px;
      font-size: 13px;
    }

    .login-view .email-password-area input[type='checkbox'] + label:after {
      position: absolute;
      left: -16px;
      width: 16px;
      height: 16px;
      border: 1px solid #cacaca;
      background-color: #f6f6f6;
      content: "";
    }

    .login-view .email-password-area input[type='checkbox']:checked + label:after {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowRTVBQUVGMzJEODBFMjExODQ2N0NBMjk4MjdCNDBCNyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0RTY4NUM4NURGNEYxMUUyQUE5QkExOTlGODU3RkFEOCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0RTY4NUM4NERGNEYxMUUyQUE5QkExOTlGODU3RkFEOCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQxNDQ4M0NEM0JERkUyMTE4MEYwQjNBRjIwMUNENzQxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkZDMEMxNjY2OUVCMUUyMTFBRjVDQkQ0QjE5MTNERDU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+3YY4qgAAALlJREFUeNpi/P//PwMlgImBQjDwBrCgCwQHB+NUfObMGT9mZuboe/fuheM0ABu4fv060/fv32cBNTNycHBE4nUBNs0/f/7cAWSeMzQ0rCA5DICaNwKj+qGRkVEFUYF47ty5GWfPns2EsjsYGRlFgM5OJzoQ//37t5eLi2sRMMDec3Jypn79+lVXX1//H9HRaGJisvr379/nuLm5lwKdP9vMzOwZyekAaEA3EF8G4hZCYcQ4mhcYAAIMAJGST/dDIpNQAAAAAElFTkSuQmCC);
      background-position: -1px -1px;
    }

    @media (min-width: 767px) {
      .login-view .email-password-area.small {
        border-right: 1px solid #cacaca;
      }

      .login-view .email-password-area.small .group-email {
        margin-bottom: 21px;
      }
    }

    @media (max-width: 767px) {
      .login-view .email-password-area.small {
        border-bottom: 1px solid #cacaca;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .box {
        max-width: 350px;
      }
    }

    .login-view .email-password-area.large {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    @media (min-width: 767px) {
      .login-view .email-password-area.large {
        padding: 0 50px;
      }

      .login-view .email-password-area.large .group-email label,
      .login-view .email-password-area.large .group-password label {
        height: 45px;
        line-height: 45px;
      }
    }

    .login-view .social-area {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      padding: 0 20px;
      position: relative;
      padding-bottom: 20px;
      background-color: #f6f6f6;
    }

    .login-view .social-area .header {
      margin-bottom: -6px;
    }

    @media (max-width: 767px) {
      .login-view .social-area .header {
        padding: 0px;
      }
    }

    .login-view .social-area button {
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }

    .banner {
      display: block !important;
      margin: 0 auto;
      padding: 5px;
      max-width: 280px;
    }

    .login, .register {
      display: table;
    }

    .va-wrapper {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
    }


    .loginBtn{
      display:inline-block !important;
      float:none !important;
    }