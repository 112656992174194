@mixin background-color($color){
    background: $color none repeat scroll 0 0;
}

@mixin image-dimensions($width,$height){
    width: $width * 1px;
    height: $height * 1px;
}

@mixin border-radius($top, $right:$top, $bottom:$top, $left:$top){
    -webkit-border-radius: px2rem($top) px2rem($right) px2rem($bottom) px2rem($left) !important;
    -moz-border-radius: px2rem($top) px2rem($right) px2rem($bottom) px2rem($left) !important;
    border-radius: px2rem($top) px2rem($right) px2rem($bottom) px2rem($left) !important;
}

@mixin box-sizing($box) {
    -webkit-box-sizing: $box;
    -moz-box-sizing: $box;
    box-sizing:$box;
}

@mixin transform-scale($x,$y){
    -ms-transform: scale($x, $y); /* IE 9 */
    -webkit-transform: scale($x, $y); /* Chrome, Safari, Opera */
    transform: scale($x, $y);
}

@mixin padding-sides($size) {
  padding-left: $size;
  padding-right: $size;
}

@mixin jelly($num1, $num2) {
  stroke: nth($colors, $num1);
  cx: $left + ($count * $num2);
  stroke-width: 3px;
  animation: jump $timing;
  opacity: .7;
  animation-delay: $delay * $num2;
}

@mixin floor($num2) {
  fill: #333333;
  opacity: .05;
  rx: 0;
  ry: 0;
  cx: $left + ($count * $num2);
  cy: 48px;
  animation: shadow $timing;
  animation-delay: $delay * $num2;
}