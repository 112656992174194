

.wrapper-reply-comment {
  margin-top: 15px;
  .user-info-reply {
    margin: 5px 0;
    font-size: 14px;
    color: #666666;
  }
  .flex-btn-end {
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn-reply-comment {
      border: none;
      border-radius: 5px;
      background: mediumseagreen;
      line-height: 25px;
      letter-spacing: normal;
      padding: 5px 25px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
      opacity: 1;
      cursor: pointer;
      transition: all 0.3s ease 0s;
      &:hover {
        opacity: 0.85;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
      }
      &:focus {
        outline: none !important;
      }
      span {
        color: #fff;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}

.view-more-comments {
  color: #0085ff;
  font-size: 11px;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.item-comment {
  margin-top: 10px;
  .user-comment-info {
    span {
      font-size: 11px;
      font-style: italic;
      color: #666666;
    }
  }
  .form-edit-comment {
    .wrapper-btn-edit {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: 10px 0;
      .btn-cancel-edit {
        margin-right: 15px;
        border: none;
        border-radius: 5px;
        background: #d74e4a;
        letter-spacing: normal;
        line-height: 25px;
        padding: 5px 25px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
        opacity: 1;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        &:hover {
          opacity: 0.85;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
        }
        &:focus {
          outline: none !important;
        }  
      }
      .btn-submit-edit {
        border: none;
        border-radius: 5px;
        letter-spacing: normal;
        background: mediumseagreen;
        padding: 5px 25px;
        line-height: 25px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
        opacity: 1;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        &:hover {
          opacity: 0.85;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
        }
        &:focus {
          outline: none !important;
        }
        span {
          color: #fff;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
  .editor-comment{
    color: #0085ff;
    cursor: pointer;
    margin-left: 10px;
    font-size: 11px;
    font-style: italic;
    text-decoration: underline;
  }
}