.results-banner {
    margin-top: -20px;
    background-color: $green;
    color: $white;
    padding: px2rem(15px);
}

.results-banner h3 {
    font-size: px2rem(21px);
    color: $white;
    margin: 0;
}

.results-search-container {
    padding: px2rem(15px);
    //border-bottom: 2px solid $grey;
}

.results-search-container button {
    height: px2rem(60px);
}

.results-search-container button:hover {
    background-color: darken($green, 15) !important;
}

.item-name {
   font-size: px2rem(22px);
   white-space: nowrap;
   width: 100%;
   overflow: hidden;
   text-overflow: ellipsis;
   text-transform: capitalize !important;
}

.results-content {
    margin: 10px auto;
    padding: px2rem(10px) px2rem(10px);
    min-height: px2rem(265px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/*.item-details {
    padding-left: px2rem(10px);
    float: right;
    width: 60%;
    min-height: 100%;
    margin: 0 auto;
}*/

.item-details h3 {
    padding: 0;
    padding-right: px2rem(5px);
    margin: 0;
    font-size: px2rem(18px);
}
.item-detail-column {
    display: flex;
    flex-wrap: wrap;
    padding:0;
}

.item-image-container {
    height: 100%;
    width:40%;
    a {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.itemImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
    object-fit: cover;
}

.item-description {
    word-wrap: break-word;
    display: inline-block;
    font-size: px2rem(20px);
}

.winedata1 {
  display: block;
}

.addBtn1, .addBtn2 {
  display: inline-block;
  font-size: 18px;
  border: 2px solid #666666;
  padding-left: 5px;
  padding-right: 5px; 
  line-height: 190%;
  margin-left: 5px;
}

.wineType1, .wineType2, .wineType3 {
  display: inline-block;
  font-size: 18px;
  border: 2px solid #666666;
  line-height: 190%;
  padding-left: 5px;
  padding-right: 5px;
  width: 300px;
  margin-top: 5px;
}

.wineType2 {
  margin-left: 20px;
}

.wineType3 {
  margin-top: 5px;
  margin-left: 40px;
}

.addBtn1:hover, .addBtn2:hover {
  cursor: pointer;
  background-color: mediumseagreen;
  color: white;
  border: 2px solid white;
}

.wineType1:hover, .wineType2:hover, .wineType3:hover {
  cursor: pointer;
}

.plusMinus {
  float: right;
  size: 24px;
  text-align: right;
  padding-right: 5px;
  color: #888888;
}

.hidden-item-desc {
    display: none;
}

.venue-distance label {
    margin-top: 0;
}

/*
*   RESTAURANT RESULTS
*/
#venue-name {
    font-size: px2rem(20px);
    padding-right: px2rem(15px);
}

#venue-name:hover {
    color: darken($orange, 15);
}
.venue-address {
    font-size: px2rem(20px);
    margin-bottom: 0;
    padding-bottom: 0;
}

.view-venue-btn {
   /* background-color: $green;
    color: $white;
    text-transform: uppercase;*/
    font-size:15px !important;
    margin-top:1rem;
}

.view-venue-btn:hover {
    background-color: lighten($green, 10);
    color: $white;
}

.venue-distance label,.venue-distance span {
    font-size: px2rem(20px);
}

.venue-phone {
    font-size: px2rem(20px);
}

.rating-text {
    font-size: px2rem(18px);
}

/*
*   Styles on bigger screen size
*/

@media only screen and (min-width: 769px) {
  .results-banner h3 {
      font-size: px2rem(24px);
  }
  .results-wrapper {
      width: 70%;
      margin: 0 auto;
      max-width: 1000px; 
  }
  .results-content {
      min-height: px2rem(340px);
  }
  /*.item-details {
      padding-top: px2rem(20px);
      padding-bottom: px2rem(20px);
      width: 50%;
      text-align: center;
      overflow: hidden;
  }*/
  .item-description {
      width: 100%;
  }
  .item-detail-column {
      display: inline-block;
  }
  .item-image-container {
      width: 50%;
  }
  
  .item-ratings-container {
      padding-top: px2rem(10px);
      margin: 0 auto;
      width: 25%;
  }
  .venue-distance label,.venue-distance span {
    font-size: px2rem(20px);
  }

  .venue-phone, .rating-text, .venue-address, .item-description{
    font-size: px2rem(20px);
  }
  #venue-name, .item-name {
      font-size: px2rem(24px);
  }
}

// Intern
.searchItemResultStyle {
  background: none !important;
  color: mediumseagreen;
  position: absolute;
  padding-left: 160px !important;
}
.yelp-rating-count {
  color: mediumseagreen;
  display: inline-block;
}

.google-text-count {
    padding-right: 5px;
    font-size: 14px;
    color: #f5a623;
    vertical-align: middle;
}

// Button Read more
.wrapper-btn-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.btn-desc {
    background: transparent;
    border: none;
    font-size: 10px;
    color: #3cb371;
    font-style: italic;
    cursor: pointer;
    display: inline-block;
    padding: none;
    &:hover {
        text-decoration: underline;
    }
    &:focus {
        outline: none !important;
    }
}

