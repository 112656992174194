.icon {
  width: px2rem(68px);
}

.menuToggle {
  background-color: $green;
  color: $white;
  text-decoration: none;

}
#toMenuItem, #toRestaurant, #toPrivateRooms {
  &:hover{
    text-decoration: underline;
  }
  color: $green;
}

.item {
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-transform 1s;
    -moz-transition: -moz-transform 1s;
    -o-transition: -o-transform 1s;
    transition: transform 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50%;
}
.item .cursorPointer{
  cursor: pointer !important;
}
.item .cursorDefault{
  cursor: default !important;
}
.non-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.item div {
    display: block;
    height: 100%;
    width: 100%;
    background: $light-grey ;
    color: $black;
    font-weight: bold;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;

}
.item .front {
  background: none;
}
.item .back {
    background: $green;
    -webkit-transform: rotateY( 180deg );
    -moz-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
    position: relative;
    top:  px2rem(-264px);
    height: px2rem(264px);

    & p {
      color: $white;
      padding: px2rem(20px);
    }
}
.item.flipped {
    -webkit-transform: rotateY( 180deg );
    -moz-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
}

/*textarea, input {
  padding:10px;
	font-family: FontAwesome, "Open Sans", Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}*/
textarea, input {
    padding: 0px;   
}
