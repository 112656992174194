#usersList{
  & .panel-default {
      max-width: 100%;
      overflow-x: scroll; 
  }
}

.metrics-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}
.metrics-table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}
.metrics-table tr {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}
.metrics-table th,
.metrics-table td {
  padding: .625em;
  text-align: center;
}
.metrics-table th {
  font-size: .85em;
  letter-spacing: .1em;
}
@media screen and (max-width: 600px) {
  .metrics-table {
    border: 0;
  }
  .metrics-table caption {
    font-size: 1.3em;
  }
  .metrics-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .metrics-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  .metrics-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  .metrics-table td:before {
    /*
    * aria-label has no advantage, it won't be read inside a .metrics-table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
  .metrics-table td:last-child {
    border-bottom: 0;
  }
}