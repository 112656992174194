#waitlistOrders {
  #btn-save-restAdmin-waitlist-settings {
    border: none;
    padding: 7px 20px;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  }

  .flex-title-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .item-rule-text {
    font-size: 15px;
    color: #333;
    display: flex;
    flex-direction: row;
    align-items: center;
    .setTime-seated {
      margin-left: 5px;
    }
    .small-input-val {
      margin: 0 10px 0 5px;
    }
    .item-checkbox-added-waitlist {
      display: inline-block !important;
    }
  }

  .small-input-val {
    max-width: 75px;
    border: 1px solid #aaa;
    padding: 2px 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    border-radius: 3px;
    border-radius: 1.25rem;
  }

  .item-checkbox-added-waitlist {
    display: block;
    label {
      cursor: pointer;
      .label-text {
        font-size: 15px !important;
        font-weight: normal;
        color: #333333;
        &::before {
          font-size: 18px !important;
          font-weight: bold;
        }
      }
    }
  }
  #requirements-added-waitlist, #requirements-seated-waitlist {
    margin-left: 20px;
  }
}

// Table Style
.wrapper-table-custom {
  .table-custom {
    border: 2px solid #666666;
    thead {
      border-bottom: 2px solid #666666;
      tr {
        td {
          border-right: 2px solid #666666;
          padding: 10px 15px;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          background: #aaaaaa;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 2px solid #666666;
        td {
          border-right: 2px solid #666666;
          text-align: center;
          font-size: 14px;
          padding: 5px 15px;
          &:last-child {
            border-right: none;
          }
          .icon-remove-time, .icon-edit {
            width: 20px;
            height: 20px;
            // background: #e42f2f;
            color: #fff;
            font-size: 15px;
            padding-top: 2px;
            cursor: pointer;
            border: none;
            border-radius: 3px;
          }
          .icon-add {
            width: 30px;
            height: 20px;
            // background: mediumseagreen;
            color: #fff;
            font-size: 15px;
            padding-top: 2px;
            cursor: pointer;
            border: none;
            border-radius: 3px;
          }
        }
        &:nth-child(2n) {
          background: #ffffff;
        }
      }
    }
  }
}