/** STYLES for menuitems/add_menu_item.ejs **/

#menu_items .row {
    margin-right: 0;
}

#menu-item-stars {
    margin-top: 0.5em;
}

#addSubmitButton {
    /*font-size:1.3em;*/
}

.input-select-btn {
    float:right;
    font-size:13px;
}
hr {
    color: #cccccc;
    border-style: solid;
}

.subh3 {
    font-size: 14px;
    margin-right: -5px;
}

.myInput {
    height:50%;
    width:40px;
    margin-top:13px;
    margin-right:10%;
}

.selected {
  opacity:1;
  font-weight:bold;
  background-color: rgba(60,179,113,1.0);
}

.unselected {
  opacity:.35;
  font-weight:100;
}

#picture-upload{
  border: 2px solid black;
}


#yelp-rating-stars {
    width: 50px;
}

/*#yelp-rating-stars img {
    margin: auto;
    height: 12px;
}*/

#yelp-review {
    margin-top: 2px;
    margin-left: 10px;
}

#yelp-logo-img {
    margin-top: -1px;
    margin-left: 2px;
    width: auto;
    height: 20px;
}

.deal-link {
    text-align: right;
}

.innerArea {
    max-height:150px;
}

.add-to-list-btn {
    height: 50px;
    width: 300px;
    font-size: 25px;
}

#items-list{
    list-style-type: circle;
    margin-left: 20px;
}

.dropdown-container {
    width: 100%;
}

.custom-add-dropdown {
    width: 100%;
    padding: 6px;
    margin-bottom: 8px;
    margin-top: 2px;
    background-color: $orange;
    color: white;
    border: none;
    outline: none;
    font-size: 13px;
}

.custom-add-dropdown:focus {
    border: 1px solid $light-grey;
    border-radius: 2px;
}

.dropdown-option {
    font-size: 13px;
    background-color: $grey;
}

.select-title {
    font-size:  px2rem(24px);
}

// tooltip information icon tooltip
.iconInfo{
display: inline-block
    }

.iconInfo span{
    font-size: 18px;
    text-decoration: none;
    color: #3cb371;
}


.tooltip-inner {
  padding-top: 0rem !important;
  margin-top: -10rem;
  cursor:pointer;
  background-color: #aeb1b7;
}
