/*
*   Profile View Styles
*/

/*** DIETARY PREFERENCES ***/

.preference-columns {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  height: auto;
}
.preference-items {
  display: inline;
  font-weight: normal;
}

.preference-items:hover {
  cursor: pointer;
}

@media screen and (min-width:1200px) {
   .preference-columns {
     height: 420px;
   }
}

@media screen and (min-width:1000px) and (max-width:1199px) {
  .preference-columns {
    height: 420px;
  }
}
@media screen and (min-width:770px) and (max-width:999px) {
  .preference-columns {
    height: 840px;
  }
}

/*** PAST REVIEWS ***/

.rating-img {
  max-width: 350px;
  max-height: 350px;
  margin: 0 auto;
}

.stars-container {
  max-width: 350px;
}

.edit-past-review {
  font-size: 1.3em;
}

.edit-past-review:hover {
  cursor: pointer;
}

.past-reviews-container {
  padding-top: 2px;
  padding-bottom: 5px;
  max-width: 350px;
  margin: 0 auto;
}
.center-flex-block .rating-details {
  margin-bottom: 0;
}
.center-flex-block .rated-item-price {
  padding-left: 4px;
}

.center-flex-block .rated-venue-name {
  padding-left: 4px;
}

.left-empty {
  border-color: red;
}

//onBlur profile save snackbar
#snackbar {
  visibility: hidden;
  min-width: 70px;
  max-width: 140px;
  margin-left: -35px;
  color: white;
  text-align: center;
  font-family: "Lato",sans-serif;
  font-size: 1.25rem;
  padding: 16px;
  position: fixed;
  z-index: 10000;
  left: 80%;
  bottom: 30px;
  white-space: nowrap;
}

//snackbar displayed on ajax success in blurChange in profileFunctions
#snackbar.show-success {
  visibility: visible;
  background-color: mediumseagreen;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbar.show-error {
  visibility: visible;
  background-color: red;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-left-10 {
  padding-left: 10px;
}

.padding-right-10 {
  padding-right: 10px;
}
.padding-10{
  padding: 10px;
}

.item-star-rated > .star-item-history > .raterater-rating-layer > .fa-star {
  color: #3cb371 !important;
}
.item-star-join-rated > .star-item-history > .raterater-rating-layer > .fa-star {
  color: #1b4aa9 !important;
}

.flex-title-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text-desc-bold {
  font-weight: bold;
}

#waitlist-notification-setting , #waitstaff-notification-setting {
  .flex-title-center {
    h1 {
      font-weight: bold;
    }
  }
  .wrapper-checkboxes {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    label {
      cursor: pointer !important;
      display: inline-block !important;
      position: relative;
      span {
        font-size: 15px !important;
        font-weight: bold;
        &::before {
          font-weight: bold !important;
        }
      }
      input[type='checkbox']:disabled ~ span {
        &::before {
          color: #aaa !important;
        }
      }
    }
  }

  #notify-warning-reservation {
    margin-bottom: 20px;
    padding-left: 20px;
    .warning-notify-item {
      margin: 15px 0;
      .warning-minuteAhead-item {
        margin-right: 5px;
      }
      .wrapper-list-notify-methods {
        display: block;
        width: 60%;
        margin-top: 10px;
      }
    }
  }

  .check-notPutNoti {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative;
    span {
      font-size: 15px !important;
      font-weight: bold;
      &::before {
        font-weight: bold !important;
      }
    }
  }
  #btn-save-waitlist-notify {
    padding: 7px 30px !important;
    border-radius: 5px !important;
    border: none;
    font-size: 14px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: 3px 3px 5px 6px #ccc;
    }
    &:disabled {
      background: #aaa !important;
    }
  }
  #btn-add-notify-warning {
    border-radius: 5px !important;
    border: none;
    padding: 7px 10px;
    margin-bottom: 20px;
    font-size: 14px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: 3px 3px 5px 6px #ccc;
    }
  }
}

#btn-add-warning-noti {
  border: none;
  border-radius: 5px;
  padding: 6px 25px;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #aaa !important;
  }
}

.flex-end-btn-warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
  .btn-remove-warning-noti {
    display: inline-block;
    padding: 3px 15px;
    background: red;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 0 0 0;
    .remove-warning-item {
      color: #fff;
      font-size: 16px;
    }
  }
}

#btn-cancel-warning-noti {
  background: red;
  padding: 6px 14px;
  border: none;
  border-radius: 5px;
  margin-right: 15px;
  &:focus {
    outline: none;
  }
}