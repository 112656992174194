// @import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,300,300italic,400italic,600italic,700italic);

/*normal: 400, semi-bold: 600, bold: 700; light: 300*/

/*COLORS:
Green (original):     #74c046;
Green darker:         #5d9d36;
Green light:          #d8eecb; (slider center)
Grey (original):      #566f7d;
Grey light:           #e5eaed;
Grey very light:      #f3f5f7;
Grey dark:            #41545f; (menu button type);
Grey dark:            #528a2f; (menu button border);
*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
    .yesPaddingX {
        padding-left: 5em;
        padding-right: 5em;
    }
    .yesPaddingLeft {
        padding-left: 5em;
        padding-right: 5em;
    }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .yesPaddingX {
        padding-left: 5em;
        padding-right: 5em;
    }
    .yesPaddingLeft {
        padding-left: 5em;
        padding-right: 5em;
    }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    .yesPaddingX {
        padding-left: 5em;
        padding-right: 5em;
    }
    .yesPaddingLeft {
        padding-left: 3em;
        padding-right: 3em;
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    .yesPaddingX {
        padding-left: 5em;
        padding-right: 5em;
    }
    .yesPaddingLeft {
        padding-left: 3em;
        padding-right: 3em;
    }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
    .text-xs-left {
        text-align: left;
    }
    .text-xs-right {
        text-align: right;
    }
    .yesPaddingX {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }
    .yesPaddingLeft {
        padding-left: 1em;
        padding-right: 1em;
    }
}


.container-fluid {
    /*padding-left: 0;
    padding-right: 0;*/
    padding-right:15px !important;
    padding-left:15px !important;
}

.borderRight {
    border-right: 2px solid;
}

.inBlock {
    display: inline-block;
}

.noPadding {
    padding: 0;
}

.half-m-t {
    margin-top: 0.5em;
}

.yesPaddingTop {
    padding-top: 1em;
}

.yesPaddingBottom {
    padding-bottom: 1em;
}



.yesMarginY {
    margin-top: 1em;
    margin-bottom: 1em;
}

.yesMarginY-2 {
    margin-top: 2em;
    margin-bottom: 2em;
}

.yesMarginY-3 {
    margin-top: 3em;
    margin-bottom: 3em;
}

.yesMarginRight {
    margin-right: 1em;
}

.yesMarginX {
    margin-left: 1em;
    margin-right: 1em;
}

.padToCenter {
    padding-left: 23%;
}

.noPaddingX {
    padding-left: 0;
    padding-right: 0;
}

.editForAddRestDet {
    color: blue;
}

.alignCenter {
    text-align: center;
}

// a {
//     text-decoration: none;
//     display: block;
//     color: #111111;
// }

// a:hover {
//     text-decoration: none;
//     display: block;
//     color: #0c8da0;
// }

/***** HEADER *****/

.header-container {
    border: none;
    background-color: #fff;
    padding: 0;
    margin-top: 0;
    padding-top: 1em;
    box-sizing: border-box;
    max-height: 15%;
    width: 100%;
}

/*This is important.Do not erase*/
.header-container .row {
    margin-right: 0;
}

/*.header-container .banner {*/
    /*text-align: left;*/
/*}*/

.logo {
    margin-left: 5%;
    max-width: 100px;
}

.logo img {
    width: 100%;
}

.banner {
    padding-left: 0;
    max-width: 210px;
}

.banner img {
    width: 100%;
}

.slogan {
    /*background-color: #74c046;*/
    background-color:  #6EC1C2;
    font-size: 106%;
    letter-spacing: -0.01em;
    margin-top: 1em;
    padding:  0.7% 0 0.7% 0;
    text-align: center;
    text-transform: uppercase;
    word-spacing: 0.1em;
}

.slogan p {
    color: #000;
    font-weight: 600;
    line-height: 1.8em;
    margin: 0;
}


/***** PAGE CONTENT *****/

.page-content {
    background-color: inherit;
    //padding-bottom: 6em;
    padding-left: 0;
    padding-right: 0;
}

/*hr {
    border-top-color: #cccccc;
    margin: 0.5em 0;
}*/

/*h2,
h3 {
    color: #000;
}*/

/*h2.invitetext {
    margin-top: 14px;
    font-size: 26px;
    text-align: center;
}

h2 {
    margin-top: 14px;
    font-size: 26px;
}

h3 {
    font-size: 18px;
    font-weight: bold;
}

h4 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

h4.blurbtext {
    line-height: 150%;
    padding-left: 8px;
    padding-right: 8px;
}

h6 {
    margin-bottom: 0;
}

ul {
    padding: 0 0 0 0;
    list-style-type: none;
}

img {
    width: 100%;
    margin-top: .5em;
}
*/

/***** VIEW SINGLE ITEM *****/

.subBar {
    background-color: #d8eecb;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    color: #41545f;
    padding: 10px 15px;
    text-transform: uppercase;
}

#bestHere li,
#bestHere li a {
    color: #5d9d36;
}

#viewTheDish {
    margin-bottom: 3%;
}

#viewTheDish img.theDish {
    max-width: 200px;
    float: left;
}

#viewTheDish h4 {
    font-size: 16px;
    margin-bottom: 0;
}

.yelpSmallStars {
    height: 26px;
    width: 100%;
    background-image: url('../images/stars_map@2x.png');
}

.yelpSmallStars0 {
    background-position: 522px 4222px;
}

.yelpSmallStars1half {
    background-position: 522px 4176px;
}

.yelpSmallStars4 {
    background-position: 522px 4056px;
}

img.yelpLogoImg {
    margin: 0 0 8px 0;
    width: auto;
}

#viewTopReview img {
    margin-top: 0;
    margin-bottom: 6px;
}

#viewTopReview h5 {
    color: #5d9d36;
    margin-top: 0;
    margin-bottom: 0;
}

#viewTopReview cite {
    color: #5d9d36;
    display: block;
    line-height: 1.1em;
    margin: 3px 0;
}

.reviewBody {
    color: #566f7d;
    font-size: 1.3rem;
    line-height: 1.8rem;
}

.reviewImage {
    padding-right: 0;
}

.reviewImageBest {
    max-height: 100px;
    overflow: hidden;
}

.reviewBox {
    -webkit-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #566f7d;
    color: #566f7d;
    float: right;
    margin-left: 6px;
}

.reviewBox td {
    border-right: 1px solid #566f7d;
    padding: 0 4px;
}

.bestHeader {
    margin-bottom: 10px;
}

#hours {
    margin-top: 0;
}


/***** map *****/

#viewMap {
    /*background-color: rgb(229,234,237);*/
    height: 20rem;
    margin: 2rem 0;
}


/***** FORM *****/


/*overrides bootstrap neg margin*/

.form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0;
}



input.referrer-email {
    @extend .form-control;
    width: 75%;
}

/*label,*/
.dropdown-label {
    margin-right: 8px;
    font-weight: 600;
    color: #000;
}

.scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    /*padding-left: 12px;*/
}

input[type="checkbox"] {
    margin-right: 4px;
}

button.dropdown-label {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 3px;
    width: 100%;
}

ul.dropdown-menu {
  width: 100%;
  /*padding-left: 45%;*/
}

.dropdown-header {
    color: #5d9d36;
    padding-left: 0px;
    font-size: 100%;
    font-weight: 600;
}


#distance-number,
#distanceIng-number {
    width: 50px;
    padding: 4px 4px 4px 6px;
    display: inline-block;
    height: 29px;
    margin-bottom: 6px;
}

#distanceIng-number {
    margin-right: 6px;
}

.input-group-inline,
.input-group-btn-inline {
    display: inline-block;
}


/***** DROPDOWNS, CHECKBOXES, SLIDERS, BUTTONS *****/

#sortByWhat .dropdown-menu {
    min-width: 0;
}

.dropdown-menu>li>a {
    padding: 3px 13px;
}

// .dropdown-toggle {
//     line-height: 1.1;
// }

.checkbox-inline+.checkbox-inline {
    margin-left: 0;
}

.checkbox-inline:last-of-type {
    margin-right: 0;
}

.slider-selection,
.tick-slider-selection,
.in-selection {
    background: #02843d;
}

.slideDiv {
    color: #566f7d;
    display: table;
    font-weight: 700;
    width: 100%;
}



.sliderLabel:first-of-type {
    padding-right: 14px;
}

.sliderLabel:last-of-type {
    padding-left: 14px;
}

.checkbox-inline {
    font-size: 12px;
}

button[type="submit"] {
    background-color: $green;
    background-image: none;
    color: #ffffff !important;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 1px;
    text-align: center;
    text-shadow: none;
    text-transform: uppercase;
}

button[type="submit"]:hover {
    color: #ffffff;
    font-weight: 400;
    opacity: 0.9;
    font-size: 1em;
    text-shadow: none;
    text-transform: uppercase;
}


// button[type="view"] {
//     background-color: #e41a6a;
//     background-image: none;
//     color: #ffffff;
//     font-weight: 400;
//     text-align: center;
//     text-shadow: none;
// }

span.blueUnderline {
    color: blue;
    text-decoration: underline;
}

.submitDiv {
  text-align: center;
  padding: 10px 0 50px;
}

/**********/

#moreOptionsLink {
    text-align: center;
}

.moreCTA a {
    color: #566f7d;
    font-weight: 600;
}

.more {
    font-size: 12px;
}

.more label {
    font-size: 14px;
    line-height: 16px;
}

.more p {
    margin-bottom: 0;
}

.more p:last-of-type {
    margin-bottom: 10px;
}

span.moreLabel {
    font-weight: 600;
}

.info p {
    /*font-weight: */
}

#moreRestaurant,
#view_single_item_moreOptions,
#bestHere,
#viewTraditional {
    display: none;
}

span.labelCaps {
    text-transform: uppercase;
}


/***** BEST MENU ITEMS AT THIS RESTAURANT *****/

li.best {
    border-bottom: 1px solid;
    padding-bottom: 12px;
}

li.best:last-of-type {
    border-bottom: none;
}

.ellipses {
    /*word-wrap: break-word;*/
    height: 110px;
    margin-bottom: 0;
}


/***** font-awesome *****/

.fa-chevron-circle-down:before,
.fa-chevron-circle-right:before {
    color: #5d9d36;
    margin-left: 2px;
}


/***** star ratings ****/

/*.starsRow {
    margin: 6px 0 16px 0;
}*/

.starsDiv,
.starsDivHalf {
    padding: 0;
}

.starsDiv label,
.starsDivHalf label {
    margin: 0 0 5px 0;
    width: 100%;
    line-height: 16px;
}

.starsDiv i,
.starsDivHalf i {
    font-size: 24px;
    color: #74c046;
    letter-spacing: 1px;
    margin-top: 4px;
}

.starsDivHalf i {
    font-size: 18px;
}

.starsDivSmall {
    margin-bottom: -9px;
}

.starsDivSmall i {
    font-size: 14px;
}

.starsDiv h4 {
    margin-bottom: 2px;
    margin-top: 0;
}

.fa-fw {
    width: auto;
}


/***** FOOTER *****/


/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e5eaed+0,e5eaed+100&amp;0+0,1+20 */

#footer {
    position: relative;
    left: 0px;
    bottom: 0px;
    height: 72px;
    padding-top: 30px;
    text-align: center;
    width: 100%;
    z-index: 9;
    background-color: #e5eaed;
    background: -moz-linear-gradient(top, rgba(229, 234, 237, 0) 0%, rgba(229, 234, 237, 1) 30%, rgba(229, 234, 237, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(229, 234, 237, 0)), color-stop(30%, rgba(229, 234, 237, 1)), color-stop(100%, rgba(229, 234, 237, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(229, 234, 237, 0) 0%, rgba(229, 234, 237, 1) 30%, rgba(229, 234, 237, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(229, 234, 237, 0) 0%, rgba(229, 234, 237, 1) 30%, rgba(229, 234, 237, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(229, 234, 237, 0) 0%, rgba(229, 234, 237, 1) 30%, rgba(229, 234, 237, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(229, 234, 237, 0) 0%, rgba(229, 234, 237, 1) 30%, rgba(229, 234, 237, 1) 100%);
    /* W3C */
    /*filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00e5eaed', endColorstr='#e5eaed', GradientType=0);*/
    /* IE6-9 */
}

.menu-button {
    /*background-color: #74c046;*/
    background-color: #6EC1C2;
    height: 42px;
    border-right: 2px solid #004447;
    padding: 11px 0 0 0;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
}
div.menu-button a {
    text-decoration: none;
    display: block;
    color: #111111;
}

div.menu-button a:hover {
    text-decoration: none;
    display: block;
    color: #C93413;
}

.menu-button:last-of-type {
    border-right: none;
}

.hyperlinks{
    color: #0B0080;
}

.itemImage img {
    width: 295px;
}

.greyText {
    color: #5e5e5e;
}

.font12 {
    font-size: 12px;
}


.verticalCenter {
    vertical-align: middle;
}

/* For profile modal */
.modal-backdrop.in {
    z-index: auto !important;
}

.init-hide {
    display: none;
}