/** Classes for special countered list, such as in Terms of Service **/

.list-section {
  counter-reset: section;
  list-style-type: none;
  *list-style-type: decimal; //For IE7 support
}

.list-section > li {
  position: relative;
}

.list-section > li::before {
  position: absolute;
  left: -2em;
}

.list-subsection {
  counter-reset: subsection;
  list-style-type: none;
  *list-style-type: decimal;
}

.list-subsection > li {
  position: relative;
}

.list-subsection > li::before {
  position: absolute;
  left: -2.5em;
}

.list-subsubsection {
  counter-reset: subsubsection;
  list-style-type: none;
  *list-style-type: decimal;
}

.list-subsubsection > li {
  position: relative;
}

.list-subsubsection > li::before {
  position: absolute;
  left: -3.5em;
}


.list-section li::before {
  content: counter(section) ". ";
  counter-increment: section;
}

.list-subsection li::before {
  content: counter(section) "." counter(subsection) ". ";
  counter-increment: subsection;
}

.list-subsubsection li::before {
  content: counter(section) "." counter(subsection) "." counter(subsubsection) ". ";
  counter-increment: subsubsection;
}